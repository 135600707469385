
export const abeyFormat = (num) => (new Intl.NumberFormat('en', {
  minimumFractionDigits: 0,
}).format(parseInt(num)));

export const countFormat = (num) => (new Intl.NumberFormat('en', {
  minimumFractionDigits: 0,
}).format(parseInt(num)));

export const ticketFormat = (num) => (new Intl.NumberFormat('en', {
  minimumFractionDigits: 2
}).format(num));

export const priceFormat = (num, minimumFractionDigits = 0) => (new Intl.NumberFormat('en', {
  minimumFractionDigits: minimumFractionDigits,
  maximumFractionDigits: 8
}).format(num));

export const numberFormatterFactory = (
    minFractionDigits = 0,
    maxFractionDigits = 3,
    minIntegerDigits = 1,
    localizationFormat = 'en-EN',
    postfix = '',
) => {
    const numberF = new Intl.NumberFormat(localizationFormat, {
        minimumIntegerDigits: minIntegerDigits,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits
    });

    // get thousands delimiter from locale
    let thousands = numberF.format(1000)[1];
    let thousandsDelimiter = isNaN(+(thousands))
        ? thousands
        : '';

    // get decimal delimiter from locale
    let decimal =  numberF.format(0.1)[1];
    let decimalDelimiter = isNaN(+(decimal))
        ? decimal
        : '';

    let otherSymbolRegex;
    if (thousandsDelimiter !== '' && decimalDelimiter !== '')
        otherSymbolRegex = new RegExp(`[^0-9|\\${thousandsDelimiter}|\\${decimalDelimiter}]+`, 'g');
    else if (thousandsDelimiter !== '' || decimalDelimiter !== '')
        otherSymbolRegex = new RegExp(`[^0-9|\\${thousandsDelimiter || decimalDelimiter}]+`, 'g');
    else
        otherSymbolRegex = new RegExp(`[^0-9]+`, 'g');

    let oneDecimalDelimitterRegex = decimalDelimiter !== ''
        ? new RegExp(`[${decimalDelimiter}](?=.*[${decimalDelimiter}])`, 'g')
        : null;

    const params = {
        thousandsDelimiter: thousandsDelimiter,
        decimalDelimiter: decimalDelimiter,
        otherSymbolRegex: otherSymbolRegex,
        oneDecimalDelimitterRegex: oneDecimalDelimitterRegex,
    };

    return {
        format: (sVal) => {
            if (sVal === '') {
                return 0;
            }
            if(sVal === params.decimalDelimiter)
                sVal = '0' + params.decimalDelimiter;
            let isLastDecimalChar = sVal.length && sVal.length > 1 && sVal[sVal.length - 1] ===  params.decimalDelimiter;
            sVal = sVal.toString().replace(postfix, '');
            sVal = params.oneDecimalDelimitterRegex
                ? sVal.replaceAll(params.oneDecimalDelimitterRegex, '')
                : sVal;
            sVal = sVal.replaceAll(params.otherSymbolRegex, '')
            sVal = sVal.replaceAll(/^([0]+)([0-9])/g, '$2') // replace 0 before if next int symbol

            if(params.thousandsDelimiter !== '') {
                sVal = sVal.replaceAll(params.thousandsDelimiter, '');
            }

            let unsignedZeros = '';
            if(params.decimalDelimiter !== '') {
                sVal = sVal.replace(params.decimalDelimiter, '.');
                let decimal = sVal.split('.')[1] ?? '';
                let lastZero = /0+$/.exec(decimal) ?? '';
                lastZero = lastZero !== ''
                    ? lastZero[0]
                    : '';
                unsignedZeros = lastZero !== '' && +lastZero === 0
                    ? lastZero.slice(0, maxFractionDigits - (decimal.length - lastZero.length))
                    : '';
            }

            let nVal = (+sVal).toFixed(maxFractionDigits);
            nVal = isNaN(nVal) ? 0 : nVal;

            let sResult = numberF.format(nVal);
            if (unsignedZeros.length) {
                sResult =  sResult + (sResult.indexOf(params.decimalDelimiter) === -1 ? params.decimalDelimiter : '') + unsignedZeros;
            }
            return sResult + (isLastDecimalChar ? params.decimalDelimiter : '');
        },
    };
};

const currencyTypes = {
    USD: '$'
}
export function currencyFormat(currencyObj) {
    const currency = currencyTypes?.[currencyObj.currency] ?? `${currencyObj.currency} `;
    return `${currency}${abeyFormat(currencyObj.decimal)}`
}
