import {useEffect, useState} from "react";
import { OverlayTrigger} from "react-bootstrap";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import { setSysWalletAddress, skipAllPromt } from "../../../actions";
import Modal from "../../../components/refactoring/ModalPage";
import Alert from "../../../components/refactoring/Alert";

import cn from "classnames";

const ModalSysWallet = ({intl, member,  mobile,  onClose, show, promt, skipAllPromt}) => {
    const { formatMessage } = intl;
    const [copied, setCopied] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const idTooltip = setTimeout(() => {
            setShowTooltip(false);
        }, 2000);
        const idCopied = setTimeout(() => {
            setCopied(false);
        }, 2500);
        return () => {
            clearTimeout(idTooltip);
            clearTimeout(idCopied);
        }
    }, [copied])

    const isPromt = !promt.complitedPromt.skipAll && (!promt.complitedPromt.aw || !promt.complitedPromt.mobileAw);

    const onCopyOver = () => {
        if(!showTooltip) {
            setShowTooltip(true);
        }
    };
    const onCopyOut = () => {
        if(showTooltip && !copied) {
            setShowTooltip(false);
        }
    };
    const onCopyClick = () => {
        setCopied(true);
        setShowTooltip(true);
        window.navigator.clipboard.writeText(member.sysWalletAddress ?? "N/A");
    };

    const onKeyDownHandler = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler);
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
        };
    }, []);

    return (<>
        <Modal.Container  show={!!show} onClose={onClose} className={cn({'promt-content ': isPromt})}>
            <Modal.Header > {formatMessage({id:"AW458.SYSTEM_WALLET"})}</Modal.Header>
            
            <Modal.Body>

                <div
                    className={"description"}
                    dangerouslySetInnerHTML={{__html: formatMessage({id:"AW458.AW_SYSTEM_WALLET_SESC"})}}
                ></div>

                <div className="marketplace-sell-action w-100 gap-3">
                    {member.sysWalletAddress
                        ? 
                        <>
                            <div className="form-block">
                                <div className="label mb-1">
                                    {formatMessage({'id':'COMMON.WALLET_ADDRESS'})}:
                                </div>
                                <div className="value">
                                    <div className="d-flex">
                                        <div className={cn("flex-1 me-2", (mobile ? 'redesign-text-break-all' : 'redesign-text-ellipsis'))} >
                                            {member.sysWalletAddress}
                                        </div>
                                        <div {...{onMouseOver:onCopyOver, onMouseOut:onCopyOut}}>
                                            <OverlayTrigger
                                                placement={'left'}
                                                trigger={['click', 'hover']}
                                                show={showTooltip}
                                                overlay={ ({ arrowProps, placement, ref, style }) => {
                                                    return (
                                                        <div className="tooltip me-2" ref={ref} style={style}>
                                                            <span className="tooltip-inner" dangerouslySetInnerHTML={{__html: formatMessage({id:copied ? 'COMMON.COPIED':'COMMON.COPY'})}} />
                                                        </div>
                                                    );
                                                }
                                                }>
                                                <div className="">
                                                    
                                                    <i className="faw-copy cursor-pointer" onClick={onCopyClick}  />
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </>
                         : <p>{formatMessage({id:'LINKED_WALLET.YOU_HAVE_TO_LINK'})}</p>
                    }
                </div>

                {member.sysWalletAddress && <Alert text={formatMessage({id:"AW458.DEPOSIT_SYSTEM_WALLET_PATIENT"})} type='info'></Alert>}

            </Modal.Body>
            <Modal.Footer>
                {isPromt 
                    ? <>
                        <button className='redesign-btn btn-square outline-common' 
                            onClick={() => {
                                skipAllPromt();
                                onClose();
                            }}

                        >
                            {formatMessage({id:"COMMON.SKIP"})}
                        </button>
                        <button
                            className='redesign-btn btn-square filled'
                            onClick={onClose}
                            >
                                {formatMessage({id:"COMMON.NEXT"})}
                        </button>
                    </>

                    : <button
                        className='redesign-btn btn-square filled'
                        onClick={onClose}
                        >
                            {formatMessage({id:"COMMON.OK"})}
                    </button>
                }

            </Modal.Footer>
        </Modal.Container>
    </>);
};

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
        promt: state.promt,
    }),
    (dispatch) => ({
        setSysWalletAddress: (address) => {
            dispatch(setSysWalletAddress(address));
        },
        skipAllPromt: (state) => {
            dispatch(skipAllPromt(state));
        },
    }),
)(injectIntl(ModalSysWallet));
