import InjectIntlProvider from "./InjectIntlProvider";
import DocTitleUpdate from "./DocTitleUpdate";
import LoadCommonData from "./LoadCommonData"
import AuthChecker from "./AuthChecker";
import ClickerCatcher from "./CommonHandler";
import PromtsManagment from "./PromtsManagment";
import InjectKyWrapperCallback from "./InjectKyWrapperCallback";
import ModalAbeyWallet from '../routes/root/main/ModalAbeyWallet';
import ModalLinkedWalet from '../routes/root/main/ModalLinkedWalet';
import ModalError from '../routes/root/main/ModalError';
import ModalSuccess from '../routes/root/main/ModalSuccess';
import {connect} from "react-redux";
import SetMqttConnection from './SetMqttConnection';
import MapLoader from './MapLoader';
import View from '../components/main-view/View';
import Mobile from "../services/Mobile";

const Middleware = ({children, showModalAbeyWallet, logged, showModalError, showSuccessModal, showModalLinkedWalet}) => {

    return (
        <InjectIntlProvider>
            <AuthChecker>
                <InjectKyWrapperCallback />
                <LoadCommonData>
                    <Mobile>
                        <PromtsManagment>
                            <DocTitleUpdate >
                                <ClickerCatcher>
                                    { logged && <SetMqttConnection />}
                                    <MapLoader />
                                    <View>
                                        {children}
                                    </View>
                                    { logged && showModalAbeyWallet && <ModalAbeyWallet />}
                                    { showModalError && <ModalError />}
                                    { showSuccessModal && <ModalSuccess />}
                                    { showModalLinkedWalet && <ModalLinkedWalet />}
                                </ClickerCatcher>
                            </DocTitleUpdate>
                        </PromtsManagment>
                    </Mobile>
                </LoadCommonData>
            </AuthChecker>
        </InjectIntlProvider>
    );
};

export default connect(
    (state) => ({
        showModalLinkedWalet: state.common.showModalLinkedWalet,
        showModalAbeyWallet: state.common.showModalAbeyWallet,
        showModalError: state.error.showModalError,
        showSuccessModal: state.crossSuccess.showSuccessModal,
        logged: !!state.member.id,
    }),
    (dispatch) => ({}),
)(Middleware);
