import {Form} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {useState} from "react";

export const FormInput = ({
  intl,
  label,
  subLabel,
  subLabelhtml,
  refInput,
  controlId,
  groupClassName,
  inputClassName="",
  groupStyle,
  controlName,
  controlType,
  placeholder,
  error,
  errorParams,
  min,
  max,
  onControlChange = () => {},
  onControlBlur = () => {},
  onKeyPress = () => {},
  onKeyDown = () => {},
  onInput = () => {},
  disabled = false,
  defaultValue = '',
  selectOptions
}) => {
    const { formatMessage } = intl ? intl : {};
    const [passwordText, setPasswordText] = useState(false);
    const handleChangePasswordType = () => setPasswordText(!passwordText);
    const groupClasses = [
        groupClassName ?? '',
        controlType === 'password' ? 'position-relative':''
    ];

    const controlClasses = [
        (inputClassName ?? "") + (error ? ' is-invalid':''),
    ];
    const errorText = error 
        ? (Array.isArray(error)
            ? error.map((item) => {
                if (formatMessage) {
                    return formatMessage({id:item}, errorParams ? (errorParams[item] || [errorParams.attribute]) : "");
                } else {
                    switch (item) {
                        case 'auth.failed':
                            return 'Auth failed';
                        default:
                            return item;
                    }
                }
            }).join('<br />')
            : error)
        : '';

    onControlChange = onControlChange || (() => {});

    const EyePassword = () => (<i onClick={handleChangePasswordType} style={{'fontSize':'1.5rem','top':'36px','right':'19px','color':'rgba(75, 86, 119, 0.85)'}} className={"position-absolute cursor-pointer d-block " + (passwordText ? 'faw-eye_open':'faw-eye_close')} alt=""/>);

    return (
        <Form.Group style={groupStyle} className={`${groupClasses.join(' ')}`} controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            {controlType === 'select' 
                ?<Form.Select ref={refInput ? refInput:null}
                    defaultValue={defaultValue}
                    onChange={onControlChange}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    onBlur={onControlBlur}
                    onInput={onInput}
                    disabled={disabled}
                    min={min}
                    max={max}
                    className={`${controlClasses.join(' ')}`}
                    type={passwordText ? 'text':controlType}
                    name={controlName}
                    placeholder={placeholder}>
                    {
                        selectOptions.map(({value, title}, indx) => {
                            return <option key={indx} value={value}>{title}</option>;
                        })
                    }
                </Form.Select>

                :<Form.Control
                    ref={refInput ? refInput:null}
                    defaultValue={defaultValue}
                    onChange={onControlChange}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    onBlur={onControlBlur}
                    onInput={onInput}
                    disabled={disabled}
                    min={min}
                    max={max}
                    className={`${controlClasses.join(' ')}`}
                    type={passwordText ? 'text':controlType}
                    name={controlName}
                    placeholder={placeholder}
                />
            }
            { controlType === 'password' ? <EyePassword />:null }
            { error && (error !== true) && <div className={"form-control-error"} dangerouslySetInnerHTML={{__html: errorText}} /> }
            
            {!!subLabel && <Form.Label>{subLabel}</Form.Label>}
            {!!subLabelhtml && <Form.Label dangerouslySetInnerHTML={{__html: subLabelhtml}}></Form.Label>}
        </Form.Group>
    )
};

export default injectIntl(FormInput);
