import Header from "../../routes/root/main/Header";
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import MarketplaceNav from "./MarketplaceNav";
import AdsNav from "./AdsNav";
import SupportNav from "./SupportNav";

const View = ({children, mobile, logged}) => {

    const { lang } = useParams();
    let navigate = useNavigate();

    const [leftNav, setLeftNav] = useState(null);
    const postfix = window.location.pathname.replace(`/${lang}/`, '');

    useEffect(() => {
        

        if (postfix.indexOf('marketplace') > -1 ) {
            setLeftNav(<MarketplaceNav></MarketplaceNav>);
        } else if (postfix.indexOf('ads-') > -1) {
            setLeftNav(<AdsNav></AdsNav>);
        } else if (postfix.match(/^support\/(all|open|in-progress|closed)/) || postfix.match(/^support\/\d+\/(conversation|history)/) || [`announcements`, 'events', 'tutorials'].indexOf(postfix) !== -1) {
            setLeftNav(<SupportNav></SupportNav>);
        } else {
            setLeftNav(null);
        } 

    }, [lang, navigate]);

    return (<>
        {(logged || postfix.indexOf('loader') > -1)
            ? <div className="d-flex h-100 flex-column">
                <Header/>
                <div className="content-wrapper flex-1 bg_injection">
                    <div className="content-body mx-auto d-flex gap-4">
                        {(!mobile &&  leftNav) && <div className="content-left">
                            {leftNav}
                        </div>}
                        <div className="content-main flex-1">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
            : children
        }
        
    </>);
};

export default connect(
    (state) => ({
        mobile: state.common.mobile,
        logged: !!state.member.id,
    }),
    (dispatch) => ({}),
)(View);
