import api from './core/api';
import qs from "qs";

class Registration {
    constructor(api) {
        this.api = api;
    }
    getGeoIp(signal) {
        return this.api.client.get(this.api.getUrl('public/geolocation'), {signal});
    }
    emailCheck(email, code, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/verify/email/check'),
            {...options, searchParams:qs.stringify({email, code})}
        );
    }
    emailSend(email, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/verify/email/send'),
            {...options, searchParams:qs.stringify({email})}
        );
    }
    phoneCheck(phone, phoneCountry, code, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/verify/phone/check'),

            {...options, searchParams: qs.stringify({phone, phoneCountry, code,} ) }
        );
    }
    phoneSend(phone, phoneCountry, lang = 'en', options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/verify/phone/send'),
            {...options, searchParams:qs.stringify({phone, phoneCountry, lang})}
        );
    }
    confirmSend(phone, email, phoneCountry, lang = 'en', options = {}){
        return phone 
            ? this.phoneSend(phone, phoneCountry, lang = 'en', options = {})
            : this.emailSend(email, options = {});
    }
    check(email, phone, phoneCountry, code, options = {}){
        return phone 
            ? this.phoneCheck(phone, phoneCountry, code, options)
            : this.emailCheck(email, code, options);
    }

    register(data, options) {
        return this.api.client.post(
            this.api.getUrl('member/register'),
            {...options, searchParams:qs.stringify(data)}
        );
    }
}

export default (new Registration(api));
