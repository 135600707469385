import api from '../core/api';
import qs from "qs";

class Support {
    constructor(api) {
        this.api = api;
    }
    list({
        page = 1,
        perPage = 10,
        sort = 'createdAt',
        direction = 'asc',
        group = undefined,
        filter = {
          ticketId: undefined,
          memberId: undefined,
          status: undefined,
          language: undefined,
          dateStart: undefined,
          dateEnd: undefined,
          assigneeId: undefined,
        },
    }, signal) {
        const searchParams = {page, perPage, sort, direction, filter, group};
        return this.api.client.get(
            this.api.getUrl('staff/support/list'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    ticket(ticketId, signal) {
        const searchParams = {ticketId};
        return this.api.client.get(
            this.api.getUrl('staff/support/ticket'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    memberAuth(memberId, signal) {
        const searchParams = {memberId};
        return this.api.client.get(
            this.api.getUrl('staff/member/auth'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    assigned({
        filter = {
          ticketId: undefined,
          memberId: undefined,
          dateStart: undefined,
          dateEnd: undefined,
        },
    }, signal) {
        const searchParams = {filter};
        return this.api.client.get(
            this.api.getUrl('staff/support/assigned'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    assign({
        ticketId = undefined,
        assigneeStaffId = undefined
    }, signal) {
        const searchParams = {ticketId, assigneeStaffId};
        return this.api.client.put(
            this.api.getUrl('staff/support/ticket/assign'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    status({
        ticketId = undefined,
        status = undefined
    }, signal) {
        const searchParams = {ticketId, status};
        return this.api.client.put(
            this.api.getUrl('staff/support/ticket/status'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    history({
        ticketId = undefined,
    }, signal) {
        const searchParams = {ticketId};
        return this.api.client.get(
            this.api.getUrl('staff/support/ticket/history'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    language({
        ticketId = undefined,
        language = undefined
    }, signal) {
        const searchParams = {ticketId, language};
        return this.api.client.put(
            this.api.getUrl('staff/support/ticket/language'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    counters(signal) {
        return this.api.client.get(
            this.api.getUrl('staff/support/counters'),
            { signal}
        );
    }
    languages(signal) {
        return this.api.client.get(
          this.api.getUrl('staff/support/languages'),
          {signal}
        );
    }
    staffList(signal) {
        return this.api.client.get(
        this.api.getUrl('staff/support/staff-list'),
        {signal}
        );
    }
    create(params, signal) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(params)) {
            if (key === 'attachmentsId') continue;
            const trimmed = value?.trim();
            if (trimmed)
                formData.append(key, trimmed || '');
        }

        for (const [key, value] of params.attachmentsId.entries()) {
            formData.append(`attachmentsId[${key}]`, value);
        }

        return this.api.client.post(
            this.api.getUrl('staff/support/ticket'),
            {body: formData, signal}
        );
    }
    message(params, signal) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(params)) {
            if (key === 'attachmentsId') continue;
            if (value)
                formData.append(key, value || '');
        }

        for (const [key, value] of params.attachmentsId.entries()) {
            formData.append(`attachmentsId[${key}]`, value);
        }

        return this.api.client.post(
            this.api.getUrl('staff/support/ticket/message'),
            {body: formData, signal}
        );
    }
    deleteFile(id, signal) {
        return this.api.client.delete(
            this.api.getUrl('staff/support/ticket/attachment'),
            {searchParams: qs.stringify({attachmentId: id}), signal}
        );
    }

    addAttachment(data, options = {}) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if (value)
                formData.append(key, value || '');
        }
        return this.api.client.post(
            this.api.getUrl('staff/support/ticket/attachment'),
            {...options,
                timeout: 100000,
                body: formData,
            }
        );
    }

    statistics({
        page = 1,
        perPage = 15,
        sort = 'id',
        direction = 'desc',
        filter = {
            dateStart: undefined,
            dateEnd: undefined,
        }
    }, signal) {
        const searchParams = {page, perPage, sort, direction, filter};

        return this.api.client.get(
            this.api.getUrl('staff/support/statistics'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
}

export default (new Support(api));
