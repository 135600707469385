import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useState } from 'react';

import Portal from "../../../../components/Portal";
import MapLand from "../../../../components/MapLand";

import cn from "classnames";
import styles from "../assets/styles/footer.module.scss";
import imgLogo from "assets/logo_black.svg";

export const Footer = ({intl, mobile, isShowAnoncement}) => {
  const { lang } = useParams();
  const {formatMessage} = intl;
  const postfix = window.location.pathname.replace(`/${lang}/`, '');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleIsActive = (check) => {
    return {[styles.active]: postfix === check}
  };

  const year = (new Date()).getFullYear();
  return (<div className={styles.vlFooter}>
      <div className={styles.vlFooterContainer}>
        <Link to={`/${lang}`} className={styles.vlFooterLogo}>
          <img src={imgLogo} alt="" />
        </Link>
        <div className={styles.vlFooterRight}>
          <div className={styles.vlFooterMenu}>
            <Link className={cn(styles.vlFooterMenuItem, {[styles.active]: postfix === `/${lang}`}) } to={`/${lang}`}>{formatMessage({'id': 'LINK.HOME'})}</Link>
            <Link className={cn(styles.vlFooterMenuItem, handleIsActive('about'))} to={`/${lang}/about`}>{formatMessage({'id': 'LINK.ABOUT'})}</Link>
            <a href="#" className={cn(styles.vlFooterMenuItem)} onClick={handleShow}>{formatMessage({'id': 'LINK.MAP'})}</a>
            {process.env.REACT_APP_DAO_LINK === 'enabled' && <Link className={cn(styles.vlFooterMenuItem, handleIsActive('dao'))} to={`/${lang}/dao`}>{formatMessage({'id': 'LINK.DAO'})}</Link>}
            {/*<Link className={cn(styles.vlFooterMenuItem, {[styles.active]: postfix === `ads-info`})} to={`/${lang}/partners`}>{formatMessage({'id': 'LINK.PARTNERS'})}</Link>*/}
            {/*<Link className={cn(styles.vlFooterMenuItem, handleIsActive('marketplace-info'))} to={`/${lang}/marketplace-info`}>{formatMessage({'id': 'LINK.MARKETPLACE'})}</Link>*/}
            {isShowAnoncement && <Link className={cn(styles.vlFooterMenuItem, handleIsActive('public-announcements'))} to={`/${lang}/public-announcements`}>{formatMessage({'id': 'HEADER.ANNOUNCEMENTS'})}</Link>}
          </div>
          <div className={styles.vlFooterAddress}>{process.env.REACT_APP_COMPANY_ADDRESS || ''}</div>
          <div className={styles.vlFooterPdf}>
            <a download href="/pdf/Privacy_policy.pdf" target="_blank" rel="noreferrer">{formatMessage({'id': 'LANDING.PRIVACY_POLICY'})}</a>
            <a download href="/pdf/Terms_of_use.pdf" target="_blank" rel="noreferrer">{formatMessage({'id': 'LANDING.TERMS_OF_USE'})}</a>
          </div>
          <div className={styles.vlFooterCopyright}>{formatMessage({id:"LANDING.FOOTER_TITLE"}, {year: year})}</div>
        </div>
      </div>
      <Portal wrapperId='footer-modal'>
        <MapLand {...{show, onHide:handleClose}}/>
      </Portal>
  </div>)
};

export default connect(
  (state)=>({
    mobile: state.common.mobile,
    isShowAnoncement: state.common.isShowAnoncement,
  }),
  ()=>({

  })
)(injectIntl(Footer));
