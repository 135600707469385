import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import Modal from "../../../components/refactoring/ModalPage";
import { setSysWalletAddress, setMarketplaceContentType,skipAllPromt } from "../../../actions";
import ticketsIcon from "../../../assets/tickets.svg";

import cn from "classnames";

const ModalTickets = ({intl, member,  mobile,  onClose, show, promt, setMarketplaceContentType, skipAllPromt, onSkip}) => {
    const { formatMessage } = intl;
    const navigate = useNavigate();
    const { lang } = useParams();

    const onPurchase = () => {
        setMarketplaceContentType('buy-tickets');
        navigate(`/${lang}/marketplace`);
        onClose();
    }

    const onKeyDownHandler = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    const isPromt = !promt.complitedPromt.skipAll;

    const onCheckLink = (event) => {
        if(event.target.tagName === "A") {
            skipAllPromt();
            onPurchase();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler);
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
        };
    }, []);
    return (<>
        <Modal.Container  show={!!show} onClose={onClose} className={cn({'promt-content ': isPromt})}>
            <Modal.Header > {formatMessage({id:"AW458.PLOT_TICKETS"})}</Modal.Header>

            <Modal.Body>
                <div className="marketplace-sell-action w-100 gap-3">
                    <div className="d-flex align-items-center">
                        <div className="ticket-img me-3">
                            <img
                                src={ticketsIcon}
                                alt=""
                            />
                        </div>
                        <div className="label">
                            <div className="label">
                                {formatMessage({"id":"AW458.PLOT_TICKETS"})}
                            </div>
                            <div className="value">
                                {formatMessage({"id":"MARKETPLACE.AMOUNT"})}: {member.tickets}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={"description"}
                    dangerouslySetInnerHTML={{__html: formatMessage({id:"AW458.PURCHASE_PLOT_TICKET_INFO"})}}
                    onClick={onCheckLink}
                ></div>
            </Modal.Body>

            <Modal.Footer>
                {isPromt
                    ? <>
                        <button className='redesign-btn btn-square outline-common'
                            onClick={() => {
                                skipAllPromt();
                                onSkip();
                            }}

                        >
                            {formatMessage({id:"COMMON.SKIP"})}
                        </button>
                        <button
                            className='redesign-btn btn-square filled'
                            onClick={onClose}
                            >
                                {formatMessage({id:"COMMON.NEXT"})}
                        </button>
                    </>

                    : <button
                        className='redesign-btn btn-square filled'
                        onClick={onPurchase}
                        >
                            {formatMessage({id:"AW458.PURCHASE_PLOT_TICKET"})}
                    </button>
                }

            </Modal.Footer>
        </Modal.Container>
    </>);
};

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
        promt: state.promt,
    }),
    (dispatch) => ({
        setSysWalletAddress: (address) => {
            dispatch(setSysWalletAddress(address));
        },
        setMarketplaceContentType: (address) => {
            dispatch(setMarketplaceContentType(address));
        },
        skipAllPromt: (address) => {
            dispatch(skipAllPromt(address));
        },
    }),
)(injectIntl(ModalTickets));
