import api from './core/api';
import qs from "qs";

class Support {
    constructor(api) {
        this.api = api;
    }
    list(signal) {
        return this.api.client.get(
            this.api.getUrl('member/support/list'),
            { signal }
        );
    }
    languages(signal) {
        return this.api.client.get(
          this.api.getUrl('member/support/languages'),
          {signal}
        );
    }
    create(params, signal) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(params)) {
            if (key === 'attachmentsId') continue;
            const trimmed = value?.trim();
            if (trimmed)
                formData.append(key, trimmed || '');
        }

        for (const [key, value] of params.attachmentsId.entries()) {
            formData.append(`attachmentsId[${key}]`, value);
        }

        return this.api.client.post(
            this.api.getUrl('member/support/ticket'),
            {body: formData, signal}
        );
    }
    deleteFile(id, signal) {
        return this.api.client.delete(
            this.api.getUrl('member/support/ticket/attachment'),
            {searchParams: qs.stringify({attachmentId: id}), signal}
        );
    }

    addAttachment(data, options = {}) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if (value)
                formData.append(key, value || '');
        }
        return this.api.client.post(
            this.api.getUrl('member/support/ticket/attachment'),
            {...options,
                timeout: 100000,
                body: formData,
            }
        );
    }
    ticket(ticketId, signal) {
        const searchParams = {ticketId};
        return this.api.client.get(
            this.api.getUrl('member/support/ticket'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    message(params, signal) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(params)) {
            if (key === 'attachmentsId') continue;
            if (value)
                formData.append(key, value || '');
        }

        for (const [key, value] of params.attachmentsId.entries()) {
            formData.append(`attachmentsId[${key}]`, value);
        }

        return this.api.client.post(
            this.api.getUrl('member/support/ticket/message'),
            {body: formData, signal}
        );
    }
    history({
        ticketId = undefined,
    }, signal) {
        const searchParams = {ticketId};
        return this.api.client.get(
            this.api.getUrl('member/support/ticket/history'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    closeTicket(ticketId, signal) {
        const searchParams = {ticketId};
        return this.api.client.put(
            this.api.getUrl('member/support/ticket/close'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }

}

export default (new Support(api));
