import {useCallback, useEffect} from "react";
import {useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";

// import mqtt from 'mqtt-browser';

import {
    setMqttClient,
    setMqttConnectStatus,
    setMapMqttPlotUpdatePayload,
    changePlot, setMapDistrictThumbnails,
    addMapBuiltBuildings, deleteMapBuiltBuildings
} from '../actions';

const SetMqttConnection = ({
    setClient,
    setConnectStatus,
    setMapMqttPlotUpdatePayload,
    setMapDistrictThumbnails,
    changePlot,
    client,
    mapData,
    connectStatus,
    thumbnails,
    addMapBuiltBuildings,
    deleteMapBuiltBuildings,
    memberId
}) => {
    const location = useLocation();
    const { lang } = useParams();

    useEffect(() => {
        if (client) {
            client.removeAllListeners('connect');
            client.removeAllListeners('error');
            client.removeAllListeners('reconnect');
            client.removeAllListeners('message');

            client.on('connect', () => {
                setConnectStatus('Connected');
            });
            client.on('error', (err) => {
                console.error('Connection error: ', err);
                client.end();
            });
            client.on('reconnect', () => {
                setConnectStatus('Reconnecting');
            });
            client.on('message', (topic, message) => {
                if (mapData.size) {
                    if (topic === 'plot/update') {
                        const isMapPage = !!location.pathname.match(new RegExp(`/${lang}/*`));
                        if (isMapPage) {
                            setMapMqttPlotUpdatePayload(message);
                        } else {
                            changePlot(JSON.parse(message.toString()));
                        }
                    }
                    if (topic === 'district/update') {
                        let district = JSON.parse(message.toString());
                        setMapDistrictThumbnails(thumbnails.map(item => {
                            if (item.x === district.x && item.y === district.y) {
                                return district;
                            }
                            return item;
                        }));
                    }
                    if (topic === 'building/update') {
                        const building = JSON.parse(message.toString());
                        if (building) {
                            const sx = building.area.start.x,
                                  sy = building.area.start.y,
                                  ex = building.area.end.x,
                                  ey = building.area.end.y,
                                  owned = +(building.memberId === +memberId);
                            const formatedBuilding = [sx, sy, ex, ey, owned];

                            if (building.isRemoved) {
                                deleteMapBuiltBuildings(formatedBuilding);
                            } else {
                                addMapBuiltBuildings(formatedBuilding);
                            }
                        }
                    }
                }
            });
        }
        return () => {
            if (client) {
                client.removeAllListeners('connect');
                client.removeAllListeners('error');
                client.removeAllListeners('reconnect');
                client.removeAllListeners('message');
            }
        }
    }, [client, mapData, thumbnails]);

    const mqttSub = useCallback((subscription) => {
        if (client) {
            const {
                topic,
                qos
            } = subscription;
            client.subscribe(
                topic,
                {
                    qos
                },
                (error) => {
                    if (error) {
                        console.log('Subscribe to topics error', error)
                        return
                    }
                }
            );
        }
    }, [client]);

    const mqttUnSub = useCallback((subscription) => {
        if (client) {
            const {
                topic
            } = subscription;
            client.unsubscribe(
                topic,
                error => {
                    if (error) {
                        console.log('Unsubscribe error', error)
                        return
                    }
                }
            );
        }
    }, [client]);


    const mqttConnect = (url, mqttOption) => {
        import('mqtt-browser').then(({default: mqtt}) => {
            setConnectStatus('Connecting');
            setClient(mqtt.connect(url, mqttOption));
        });
    };

    useEffect(() => {
        if (connectStatus === 'Connect') {
            mqttConnect(process.env.REACT_APP_MQTT_URL, {
                username: process.env.REACT_APP_MQTT_USERNAME,
                password: process.env.REACT_APP_MQTT_PASSWORD,
            });
        }
        if (connectStatus === 'Connected') {
            mqttSub({
                topic: 'plot/update',
                qos: 0
            });
            mqttSub({
                topic: 'district/update',
                qos: 0
            });
            mqttSub({
                topic: 'building/update',
                qos: 0
            });
        }

        return () => {
            if (connectStatus === 'Connected') {

            }
        };
    }, [connectStatus, mapData, mqttSub, mqttUnSub]);

    return null;
};

export default connect(
    (state) => ({
        client: state.mqtt.client,
        connectStatus: state.mqtt.connectStatus,
        mapData: state.map.data,
        thumbnails: state.map.districtThumbnails,
        memberId: state.member.id
    }),
    (dispatch) => ({
        setConnectStatus: (status) => {
            dispatch(setMqttConnectStatus(status));
        },
        setClient: (client) => {
            dispatch(setMqttClient(client));
        },
        setMapMqttPlotUpdatePayload: (payload) => {
            dispatch(setMapMqttPlotUpdatePayload(payload));
        },
        setMapDistrictThumbnails: (thumbnails) => {
            dispatch(setMapDistrictThumbnails(thumbnails))
        },
        changePlot: (data) => {
            dispatch(changePlot(data))
        },
        addMapBuiltBuildings: (buildings) => {
            dispatch(addMapBuiltBuildings(buildings))
        },
        deleteMapBuiltBuildings: (buildings) => {
            dispatch(deleteMapBuiltBuildings(buildings))
        }
    }),
)(SetMqttConnection);
