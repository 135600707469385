import {useCallback} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {changeCrossSuccessList} from "../../../actions";
import Portal from "../../../components/Portal";
import cn from "classnames";

const ModalCrossSuccess= ({crossSuccessList, intl , changeCrossSuccessList}) => {
    const { formatMessage } = intl;

    const close = useCallback(() => {
        changeCrossSuccessList(crossSuccessList.slice(1));
    }, [crossSuccessList]);

    const firstState = crossSuccessList && crossSuccessList.length
        ? crossSuccessList[0]
        : {title: '', failed: false, text: ''}

    return (
        <Portal wrapperId="modal-success">
            <div className="confirmation-container modal-abey-wallet">
                <div className="confirmation-close-overlay"  onClick={() => close()}></div>
                <div className="modal_success">
                    <div className="modal_success__wrapper">
                        <div className="modal_success__content">
                            <div className="modal_success__icon">
                                <i className={cn(firstState.failed ? 'faw-cross_round text-red':'faw-check_round text-green')}></i>
                            </div>
                            <div className="modal_success__body">
                                <div className="modal_success__title">{(firstState.title) 
                                    ? (firstState.title) 
                                    : ((firstState.failed) 
                                        ? <>{formatMessage({id:'MARKETPLACE.ERROR'})}!</> 
                                        : <>{formatMessage({id:'COMMON.SUCCESS'})}</>
                                )}</div>
                                <div className="modal_success__text">{
                                !!crossSuccessList.length && 
                                
                                    <div className="market-buildings--congratulations-message"  dangerouslySetInnerHTML={{__html: firstState.text}}></div>
                                
                                }</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_success__action">
                        <button className="redesign-btn btn-square ghost md" onClick={() => close()}>
                            {formatMessage({'id':'COMMON.OK'})}
                        </button>
                    </div>
                </div>
            </div>
        </Portal>
    );
};

const ModalCrossSuccessComponent = connect(
    (state) => ({
        crossSuccessList: state.crossSuccess.list,
    }),
    (dispatch) => ({
        changeCrossSuccessList: (list) => {
            dispatch(changeCrossSuccessList(list));
        },
    }),
)(injectIntl(ModalCrossSuccess));

export default ModalCrossSuccessComponent;
