import styles from "../assets/styles/three.module.scss";
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import cn from "classnames";
import icon1 from "../assets/citizenship_icon.svg";
import icon2 from "../assets/createyourown-icon.svg";
import icon3 from "../assets/socialexperience_icon.svg";

export const Three = ({intl}) => {
  const {formatMessage} = intl;
  return (<>
    <div className={styles.section}>
      <div className={cn(styles.wrapper, styles._1)}>
        <div className={styles.container}>
          <div className={styles.inner}>
            <div className={styles.header}>{formatMessage({id: 'landing.vlands.three.title'})}</div>
            <img className={styles.icon} alt="" src={icon1}/>
            <div className={styles.title}>{formatMessage({id: 'landing.vlands.three.s1.title'})}</div>
            <div className={styles.text}>{formatMessage({id: 'landing.vlands.three.s1.text'})}</div>
          </div>
        </div>
      </div>
      <div className={cn(styles.wrapper, styles._2)}>
        <div className={styles.container}>
          <div className={styles.inner}>
            <img className={styles.icon} alt="" src={icon2}/>
            <div className={styles.title}>{formatMessage({id: 'landing.vlands.three.s2.title'})}</div>
            <div className={styles.text}>{formatMessage({id: 'landing.vlands.three.s2.text'})}</div>
          </div>
        </div>
      </div>
      <div className={cn(styles.wrapper, styles._3)}>
        <div className={styles.container}>
          <div className={styles.inner}>
            <img className={styles.icon} alt="" src={icon3}/>
            <div className={styles.title}>{formatMessage({id: 'landing.vlands.three.s3.title'})}</div>
            <div className={styles.text}>{formatMessage({id: 'landing.vlands.three.s3.text'})}</div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.join}>
      <div className={styles.container}>
        <div className={styles.title}>
          {formatMessage({id: 'landing.vlands.three.join.title'})}
        </div>
      </div>
    </div>
  </>);
};

export default connect(
  (state)=>({
      mobile: state.common.mobile,
  }),
  ()=>({

  })
)(injectIntl(Three));
