import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useNavigate, useParams} from "react-router-dom";
import { Nav } from 'react-bootstrap';
import AW937HideElementsForCondition from "../crutches/AW937HideElementsForCondition";

const AdsNav = ({intl}) => {

    const { lang } = useParams();
    const { formatMessage } = intl;
    const postfix = window.location.pathname.replace(`/${lang}/`, '');
    let navigate = useNavigate();

    return (
        <>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/ads-overview`);  }}
                active={[`ads-overview`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.ADS_OVERVIEW'})}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/ads-manager`);  }}
                active={[`ads-manager`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.ADS_MANAGER'})}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/ads-schedule`);  }}
                active={[`ads-schedule`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.ADS_SCHEDULE'})}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/ads-archive`);}}
                active={[`ads-archive`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'ADS.LINK_TO_ARCHIVE'})}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/ads-credit-history`);  }}
                active={[`ads-credit-history`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.ADS_CREDIT_HISTORY'})}
            </Nav.Link>
            <AW937HideElementsForCondition>
                <Nav.Link
                    href={`/${lang}/adnet-catalog`}
                    className='add-nav-item action-nav'
                >
                    {formatMessage({id:'AD_NET_LANDING.CATALOG'})}
                </Nav.Link>
            </AW937HideElementsForCondition>
        </>
    );
};

const AdsNavComponent = connect(
    (state) => ({
    }),
    (dispatch) => ({
    }),
)(injectIntl(AdsNav));

export default AdsNavComponent;
