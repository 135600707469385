import {Button, Form } from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import  FormInput from '../../components/FormInput';
import registrationService from '../../api/registration';
import {useState} from "react";
import Skeleton from "react-loading-skeleton";
import api from "../../api/core/api";
import memberService from "../../api/member";
import {
    setRegistrationAvailableIn,
    setRegistrationDecaySeconds,
    setRegistrationPhoneCountry, setRegistrationPhoneToRender,
    setRegistrationPhoneToSend, setRegistrationToken, setRegistrationMemberId
} from "../../actions";

const CreateForm = ({intl, setRegistrationStep, token, phoneCountry, phone, email, confirmType, setMemberId, setToken, setPhoneToSend, setPhoneToRender, setDecaySeconds, setAvailableIn, setPhoneCountry}) => {
    const {formatMessage} = intl;
    const { lang } = useParams();
    const [ isDataSend, setIsDataSend ] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsParams, setErrorsParams] = useState({});
    const [globalError, setGlobalError] = useState('');
    const [sourses] = useState([
        {value: "", title: formatMessage({id:'REGISTRATION.SELECT_AN_OPTION'})},
        {value: "zoom", title: "Zoom"},
        {value: "telegram", title: "Telegram"},
        {value: "twitter", title: "Twitter"},
        {value: "others", title: formatMessage({id:'REGISTRATION.OTHERS'})},
    ]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        setIsDataSend(true);
        let values = {
            token, phone: phone ? phone : undefined, email, phoneCountry: phone ? phoneCountry : undefined, lang,
            ...Object.fromEntries(data.entries())
        };
        registrationService.register(values)
            .then(resp => resp.json())
            .then(data => {
                setToken('');
                setPhoneToSend('');
                setPhoneToRender('');
                setDecaySeconds(0);
                setAvailableIn(0);
                setPhoneCountry('');

                api.setAccessToken(data.accessToken);
                memberService.member()
                    .then(resp => resp.json())
                    .then(data => {
                        setMemberId(data.id);
                        setIsDataSend(false);
                        setRegistrationStep('finish');
                    });
            })
            .catch(err => {
                setIsDataSend(false);
                if (err?.response?.status === 500) {
                    setGlobalError(formatMessage({id:'validation.something_went_wrong'}));
                } else {
                    err.response
                        .json()
                        .then(data => {
                            setErrors(data.errors);
                            setErrorsParams(data.params);
                        });
                    ;
                }
            });
    };

    const onNameChange = (value) => {
        value.target.value = value.target.value.substr(0, 64);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h5>{formatMessage({'id':"REGISTRATION.CREATE_NEW_ACCOUNT"})}</h5>
            <FormInput
                {...{
                    groupClassName: '',
                    controlId: 'formBasicName',
                    label: formatMessage({'id':"REGISTRATION.ENTER_NAME"}),
                    controlType: 'text',
                    controlName: 'name',
                    placeholder: formatMessage({'id':"COMMON.NAME"}),
                    error: errors.name,
                    errorParams: {attribute:formatMessage({'id':"COMMON.NAME"}).toLowerCase()},
                    onControlChange: onNameChange,
                }}
            />

            <FormInput
                {...{
                    groupClassName: 'mt-3',
                    controlId: 'formBasicReferrerId',
                    label: formatMessage({'id':"REGISTRATION.REFERRER_ID"}),
                    controlType: 'number',
                    controlName: 'referrerId',
                    placeholder: formatMessage({'id':"REGISTRATION.REFERRER_ID"}),
                    error: errors.referrerId,
                    errorParams: {},
                }}
            />

            {confirmType === 'phone' && <FormInput
                {...{
                    groupClassName: 'mt-3',
                    controlId: 'formBasicEmail',
                    label: formatMessage({'id':"REGISTRATION.ENTER_EMAIL"}),
                    controlType: 'email',
                    controlName: 'email',
                    placeholder: formatMessage({'id':"COMMON.EMAIL"}),
                    error: errors.email,
                    errorParams: {
                        attribute: formatMessage({'id':"COMMON.PHONE"})
                    },
                }}
            />}
            <FormInput
                {...{
                    groupClassName: 'mt-3',
                    controlId: 'formBasicPassword',
                    label: formatMessage({'id':"REGISTRATION.ENTER_PASSWORD"}),
                    controlType: 'password',
                    controlName: 'password',
                    placeholder: formatMessage({'id':"COMMON.PASSWORD"}),
                    error: errors.password,
                    errorParams: errorsParams.password
                }}
            />

            <FormInput
                {...{
                    groupClassName: 'mt-3',
                    controlId: 'formBasicPasswordConfirm',
                    label: formatMessage({'id':"REGISTRATION.ENTER_PASSWORD_CONFIRM"}),
                    controlType: 'password',
                    controlName: 'password_confirmation',
                    placeholder: formatMessage({'id':"COMMON.PASSWORD"}),
                    error: errors.password_confirmation,
                    errorParams: {},
                }}
            />

            <FormInput
                {...{
                    groupClassName: 'mt-3',
                    controlId: 'formBasicHearAboutUs',
                    label: formatMessage({'id':"REGISTRATION.WHERE_HEAR"}),
                    controlType: 'select',
                    controlName: 'hearAboutUs',
                    placeholder: formatMessage({id:'REGISTRATION.SELECT_AN_OPTION'}),
                    error: errors.hearAboutUs,
                    errorParams: {},
                    selectOptions: sourses
                }}
            />

            {
                isDataSend
                    ? <Skeleton width={"413px"} height={"48px"} className="w-100 mt-4" ></Skeleton>
                    : <Button variant="warning" className={"w-100 mt-4"} type="submit">
                        {formatMessage({'id':"REGISTRATION.REGISTER"})}
                    </Button>
            }
            {
                !globalError.length
                    ? null
                    :<div className={"form-control-error text-center mt-2"}>{globalError}</div>
            }
        </Form>
    );
};

export default connect(
    (state) => ({
        token: state.registration.token,
        phoneCountry: state.registration.phoneCountry,
        phone: state.registration.phoneToSend,
        email: state.registration.emailToSend,
    }),
    (dispatch) => ({
        setToken: (token) => {
            dispatch(setRegistrationToken(token));
        },
        setPhoneToSend: (phone) => {
            dispatch(setRegistrationPhoneToSend(phone));
        },
        setPhoneToRender: (phone) => {
            dispatch(setRegistrationPhoneToRender(phone));
        },
        setDecaySeconds: (decaySeconds) => {
            dispatch(setRegistrationDecaySeconds(decaySeconds));
        },
        setAvailableIn: (availableIn) => {
            dispatch(setRegistrationAvailableIn(availableIn));
        },
        setPhoneCountry: (phoneCountry) => {
            dispatch(setRegistrationPhoneCountry(phoneCountry));
        },
        setMemberId: (memberId) => {
            dispatch(setRegistrationMemberId(memberId));
        },
    }),
)(injectIntl(CreateForm));
