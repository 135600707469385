import {injectIntl} from "react-intl";
import {Button, Form} from "react-bootstrap";
import { useState, useEffect } from 'react';
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import { hidePhoneNumber } from '../../services/utils';
import registrationService from '../../api/registration';
import {
    setRegistrationAvailableIn,
    setRegistrationDecaySeconds,
    setRegistrationToken,
} from "../../actions";
import Skeleton from "react-loading-skeleton";

const ConfirmNumber = ({intl, confirmType, setRegistrationStep, setPreviousStep, phoneCountry, phoneToSend, emailToSend, phoneToRender, availableIn, setDecaySeconds, setAvailableIn, setToken}) => {
    const { lang } = useParams();
    const [isCheckRequested, setIsCheckRequested] = useState(false);
    const [error, setError] = useState('');
    const { formatMessage } = intl;
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    let [countdown, setCountdown] = useState(availableIn);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsCheckRequested(true);
        const inputs = Array.from(document.querySelectorAll('input[type="text"]'));
        const code = inputs
            .map((item, indx) => item.value)
            .join('');
        registrationService.check(emailToSend, phoneToSend, phoneCountry, code)
            .then(resp => resp.json())
            .then(data => {
                setIsCheckRequested(false);
                setToken(data.token);
                setRegistrationStep('createForm');
                setPreviousStep('start');
            })
            .catch(err => {
                setIsCheckRequested(false);
                inputs.map(item => item.value = '');
                document.querySelector(`[name="item0"]`).focus();
                setConfirmDisabled(true);
                err.response.json().then(data => {
                    setError(`code.${data.errors.code}`);
                });
            });

    };

    const handleSendCodeAgianClick = (event) => {
        event.preventDefault();

        registrationService.confirmSend(
            phoneToSend,
            emailToSend,
            phoneCountry,
            lang
        )
            .then(resp => resp.json())
            .then((data) => {
                setDecaySeconds(data.decaySeconds);
                setAvailableIn(data.availableIn);
                setCountdown(data.availableIn);
                countdown = data.availableIn;
            })
            .catch(err => {
            });

    };

    useEffect(() => {
        document.querySelector(`[name="item0"]`).focus();
    }, []);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            let nextCountdown = countdown;
            if (nextCountdown) {
                nextCountdown -= 1;
                setCountdown(nextCountdown);
            } else {
                clearInterval(countdownInterval);
            }
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [countdown, setCountdown]);

    const handleInputChange = (element) => {
        element.preventDefault();
        const nan = isNaN(parseInt(element.target.value));
        element.target.value = nan ? '':element.target.value;
        if (nan) {
            return;
        }
        const value = Array.from(document.querySelectorAll(`[name^="item"]`)).map(item => item.value).join('');
        let indx = parseInt(element.currentTarget.name.replace('item', ''));

        element.currentTarget.blur();
        if (indx < 3) {
            indx++;
            document.querySelector(`[name="item${indx}"]`).focus();
        }
        if (value.length === 4) {
            setConfirmDisabled(false);
            handleSubmit(element);
        }
    }

    const handleKeyDown = (event) => {
        const item = parseInt(event.target.name.replace('item', ''));
        if (event.code === "Backspace" && item > 0) {
            if (event.target.selectionStart) {
                event.target.value = '';
            } else {
                event.target.blur();
                document.querySelector(`[name^="item${item-1}"]`).value = '';
                document.querySelector(`[name^="item${item-1}"]`).focus();
            }

        }
    };

    return (
        <Form onSubmit={handleSubmit} className={"confirm"}>

            <h5 className={"mb-0"}>{formatMessage({'id': confirmType === "phone" ? "REGISTRATION.CONFIRM_NUMBER" : "REGISTRATION.CONFIRM_EMAIL"})}</h5>
            <div className="text-center mt-3 mb-4 form-note">
                {formatMessage({'id': "REGISTRATION.CODE_WAS_SENT"}, {'number':confirmType === "phone" ? hidePhoneNumber(phoneToRender) : emailToSend})}
            </div>
            <div className="d-flex justify-content-center">
                {
                    [...Array(4).keys()].map((item) => {
                        const classes = [
                            item ? 'ms-3' : '',
                            'mt-2',
                        ];
                        const controlClasses = [
                            error.length ? 'is-invalid':''
                        ];
                        return (
                            <div key={item} className={classes.join(' ')}>
                                <Form.Group className="" style={{"width": '48px'}} controlId="formBasicName">
                                    <Form.Control
                                        className={controlClasses.join(' ')}
                                        maxLength={1}
                                        type="text"
                                        style={{'paddingLeft': '18px'}}
                                        name={`item${item}`}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                    />
                                </Form.Group>
                            </div>
                        );
                    })
                }
            </div>
            {
                error.length
                    ?  <div className={"form-control-error text-center mt-2"}>{formatMessage({'id':error})}</div>
                    : null
            }
            {
                countdown
                    ? <div className="form-text mt-3 mb-4 pb-2">
                        {formatMessage({'id': "REGISTRATION.SEND_CODE_AGAIN"}, {seconds:countdown})}
                    </div>
                    : <a className="text-center w-100 d-block mt-3 mb-4" href="#" onClick={handleSendCodeAgianClick}>{formatMessage({'id': "REGISTRATION.SEND_CODE"})}</a>
            }
            {
                isCheckRequested
                    ? <Skeleton width={"413px"} height={"48px"} className="w-100" ></Skeleton>
                    : <Button variant="warning" disabled={confirmDisabled} className={"w-100"} type="submit">
                        {formatMessage({'id': "COMMON.CONFIRM"})}
                    </Button>
            }

        </Form>
    );
};

export default connect(
    (state) => ({
        phoneToSend: state.registration.phoneToSend,
        emailToSend: state.registration.emailToSend,
        phoneToRender: state.registration.phoneToRender,
        phoneCountry: state.registration.phoneCountry,
        decaySeconds: state.registration.decaySeconds,
        availableIn: state.registration.availableIn,
    }),
    (dispatch) => ({
        setDecaySeconds: (decaySeconds) => {
            dispatch(setRegistrationDecaySeconds(decaySeconds));
        },
        setAvailableIn: (availableIn) => {
            dispatch(setRegistrationAvailableIn(availableIn));
        },
        setToken: (token) => {
            dispatch(setRegistrationToken(token));
        },
    }),
)(injectIntl(ConfirmNumber));
