import cn from "classnames";

export const Alert = ({type='info', text=''}) => {

    return <div  className={cn("alert-block", type)}>
        <i className={cn({
            "faw-info_round": type === 'info',
            "faw-exclamation_point_round": type === 'warning',
            "faw-cross_round": type === 'dangerous',
        })}></i>

        <span dangerouslySetInnerHTML={{__html: text}}></span>
    </div>
}

export default Alert
