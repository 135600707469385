import {injectIntl} from "react-intl";
import Middleware from "../../services/Middleware";
import {useEffect, useState} from "react";
import Start  from "./Start";
import CreateForm  from "./CreateForm";
import ConfirmNumber  from "./ConfirmNumber";
import Congratulations  from "./Congratulations";
import {connect} from "react-redux";
import {Helmet} from "react-helmet-async";
import TopBanner from '../root/landingInterWorld/Home/TopBanner';
import cn from "classnames";
import styles from "../root/landingInterWorld/assets/styles/styles.module.scss";
import Footer from "../root/landingInterWorld/Home/Footer";

const Registration = ({intl, mobile}) => {
    const [registrationStep, setRegistrationStep] = useState('start');
    const [previousStep, setPreviousStep] = useState('start');
    const [confirmType, setConfirmType] = useState('phone');
    const { formatMessage } = intl;

    useEffect(() => {
        if (mobile) {
            document.querySelector("body")
                .setAttribute('class', 'mobile overflow-auto registration__page');
        }
    }, [mobile]);

    const getSubComponentByStep = (step) => {
        switch (step) {
            case 'start':
                return <Start setRegistrationStep={setRegistrationStep} setPreviousStep={setPreviousStep} confirmType={confirmType} setConfirmType={setConfirmType}/>;
            case 'confirmNumber':
                return <ConfirmNumber {...{confirmType, setRegistrationStep, setPreviousStep}} />;
            case 'createForm':
                return <CreateForm setRegistrationStep={setRegistrationStep}  setPreviousStep={setPreviousStep} confirmType={confirmType}/>;
            case 'finish':
                return <Congratulations confirmType={confirmType}/>;
            default:
                return;
        }
    };

    const handlePreviousButtonClick = () => {
        const stepsDict = {
            'start':'start',
            'confirmNumber':'start',
            'createForm':'start',
            'finish':'createForm',
        };
        setRegistrationStep(previousStep);
        setPreviousStep(stepsDict[previousStep]);
    };

    return (<>
        <Helmet>
            <title>{formatMessage({ id: 'SEO.SIGNUP_TITLE' })}</title>
            <meta name="description" content={formatMessage({ id: 'SEO.SIGNUP_DESC'})} />
        </Helmet>
        <div className={cn(styles.wrapper, 'w-100')}>
                <TopBanner isShort={true}/>

                <div className="d-flex align-items-center login__wrap">
                    { previousStep !== registrationStep && (registrationStep !== 'finish') && <a onClick={handlePreviousButtonClick} href="#" className={"back__button"}><i className={"faw-arrow_left d-block "} /></a> }
                    {
                        getSubComponentByStep(registrationStep)
                    }
                </div>
                <Footer />
        </div>

        
    </>);
};

const RegistrationComponent = connect(
    (state) => ({
        mobile: state.common.mobile,
    }),
    (dispatch) => ({}),
)(injectIntl(Registration));

export default () => (
    <Middleware>
        <RegistrationComponent />
    </Middleware>
);
