import { combineReducers} from "redux";
import common from "./common";
import login from "./login";
import member from "./member";
import error from "./error";

export default combineReducers({
    common,
    login,
    member,
    error,
});
