import {useEffect, useMemo, useRef, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {connect} from "react-redux";

import {
    setOpenSubmenu,
} from '../../../actions';
import {useParams} from "react-router-dom";

const Arrow = ({active, hover, open}) => {
    const dict = useMemo(() => ({'default':'#51466D', 'active':'#7929FF', 'hover':'#4D0CB9'}), []);
    const [fill, setFill] = useState(dict[active ? 'active':'default']);

    useEffect(() => {
        setFill(dict[active ? 'active':'default']);
    }, [active, dict]);

    useEffect(() => {
        setFill(dict[hover && !active ? 'hover':(active ? 'active':'default')]);
    }, [hover, dict, active]);

    return (
        <svg className={"ms-3"} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66668 0L0.726685 0.94L3.78002 4L0.726685 7.06L1.66668 8L5.66668 4L1.66668 0Z" {...{fill}} />
        </svg>
    );
};

const MenuItemWithSubmenu = ({name, items, code, pages, LabelComponent, openSubmenu, setOpenSubmenu, })  => {
    const menuItem = useRef(null);
    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}/`, '');
    const [hover, setHover] = useState(false);
    const active = pages.indexOf(postfix) !== -1;

    const fixWidth = () => {
        const [item, submenu, background] = menuItem.current.childNodes;
        const left = (item.clientWidth) + 20;
        submenu.style = `left: ${left}px`;
        background.style = `left: ${left-8}px; height:${40*items.length+16}px;width:${submenu.clientWidth+16}px`;
    };

    const onShowSubmenuClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const isEqual = code === openSubmenu;
        setOpenSubmenu(isEqual ? '':code);
        if (!isEqual) {
            setTimeout(fixWidth, 0);
        }
        return false;
    };
    const parentClasses = [
        'position-relative',
        active ? 'active':'',
        (code === openSubmenu) ? 'hover':'',
    ];

    const onMouseOver = () => {
        setHover(true);
    };
    const onMouseOut = () => {
        setHover(false);
    };

    return (
        <div className={"position-relative with-submenu"} ref={menuItem} >
            <Dropdown.Item
                className={parentClasses.join(' ')}
                onClick={onShowSubmenuClick}
                {...{onMouseOver, onMouseOut}}
                >
                <div className={"d-flex justify-content-between align-items-center"}>
                    <div>{name}</div>
                    <Arrow {...{active:active, hover:(hover || code === openSubmenu), code}} />
                </div>

            </Dropdown.Item>
            <Dropdown.Menu className={"submenu " + (openSubmenu === code ? 'show-sub':'') } >
                {
                    items.map((item, indx) => {
                        const {
                            onClick,
                            name,
                            className,
                            LabelComponent,
                            href,
                            target
                        } = item;
                        return (<Dropdown.Item href={href ?? '#'} target={target ?? "_self"} key={indx} onClick={onClick} className={className.join(' ')}>{name} {LabelComponent ? <LabelComponent />:null}</Dropdown.Item>);
                    })
                }
            </Dropdown.Menu>
            <div className={"submenu__background"}></div>
        </div>
    );
};
export default connect(
    (state) => ({
        openSubmenu:state.common.openSubmenu,
    }),
    (dispatch) => ({
        setOpenSubmenu: (openSubmenu) => {
            dispatch(setOpenSubmenu(openSubmenu));
        }
    }),
)(MenuItemWithSubmenu);
