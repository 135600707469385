import React, {useCallback} from "react";
import {connect} from "react-redux";
import {Card} from "react-bootstrap";

import {changeErrorList as changeErrorListForMember, setShowErrorModal} from "../../../actions";
import {changeErrorList as changeErrorListForStaff} from "../../../actions/staff";
import isStaff from "../../../services/isStaff";
import Portal from "../../../components/Portal";

const ModalError = ({errorList, changeErrorListForStaff, changeErrorListForMember}) => {
    const icon = <div className={"icon text-red"}><i className="d-block faw-cross_round" /></div>;

    const changeErrorList = isStaff()
        ? changeErrorListForStaff
        : changeErrorListForMember;

    const close = useCallback(() => {
        changeErrorList(errorList.slice(1));
    }, [errorList]);

    return (
        <Portal wrapperId="crop-modal">
            <div className="confirmation-container modal-abey-wallet">
                <div className="confirmation-close-overlay"  onClick={close}></div>
                <Card className={"text-center"} style={{maxWidth:'450px'}}>
                    {icon}
                    <Card.Body className={'p-0'} style={{maxHeight:'500px', overflowY:'auto'}}>
                        <div>
                            {
                                !!errorList.length && <p className={'mb-0 text-justify'}>
                                    {errorList[0]}
                                </p>
                            }
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Portal>
    );
};

const ModalErrorComponent = connect(
    (state) => ({
        errorList: state.error.list,
    }),
    (dispatch) => ({
        setShowErrorModal: (show) => {
            dispatch(setShowErrorModal(show));
        },
        changeErrorListForMember: (text) => {
            dispatch(changeErrorListForMember(text));
        },
        changeErrorListForStaff: (text) => {
            dispatch(changeErrorListForStaff(text));
        },
    }),
)(ModalError);

export default ModalErrorComponent;
