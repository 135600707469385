import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Nav } from 'react-bootstrap';
import { useNavigate, useParams} from "react-router-dom";

const SupportNav = ({intl, member }) => {

    const { lang } = useParams();
    const { formatMessage } = intl;
    const postfix = window.location.pathname.replace(`/${lang}/`, '');
    let navigate = useNavigate();

    return (
        <>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/announcements`);  }}
                active={[`announcements`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.ANNOUNCEMENTS'})}
                {!!member.unreadAnnouncements && <div className={"unread--marker ms-1"}></div>}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/events`);  }}
                active={[`events`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.EVENTS'})}
                {!!member.unreadEvents && <div className={"unread--marker ms-1"}></div>}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/support`);  }}
                active={!!postfix.match(/^support\/(all|open|in-progress|closed)/) || !!postfix.match(/^support\/\d+\/(conversation|history)/)}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.SUPPORT'})}
            </Nav.Link>
            <Nav.Link  onClick={(e) => { navigate(`/${lang}/tutorials`);  }}
                active={[`tutorials`].indexOf(postfix) !== -1}
                className='add-nav-item action-nav'
            >
                {formatMessage({id:'HEADER.TUTORIALS'})}
            </Nav.Link>
        </>
    );
};

const SupportNavComponent = connect(
    (state) => ({
        member: state.member,
    }),
    (dispatch) => ({
    }),
)(injectIntl(SupportNav));

export default SupportNavComponent;
