import {injectIntl} from "react-intl";
import { connect } from "react-redux";
import { useState } from "react";
import {useParams, useNavigate} from "react-router-dom";

import { ticketFormat } from "../../../utils/numberFormat";
import Skeleton from "react-loading-skeleton";

const PlusIcon = () => {
    const [plusRoundColor, setPlusRoundColor] = useState('default');

    const handleMouseDown = () => {
        setPlusRoundColor('active');
    };

    const handleMouseOut = () => {
        setPlusRoundColor('default');
    };

    const handleMouseOver = () => {
        setPlusRoundColor('hover');
    };

    return (
        <svg onMouseDown={handleMouseDown} onMouseOut={handleMouseOut}  onMouseOver={handleMouseOver} xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.666687 7.36654C0.666687 3.31645 3.94993 0.0332031 8.00002 0.0332031C12.0501 0.0332031 15.3334 3.31645 15.3334 7.36654C15.3334 11.4166 12.0501 14.6999 8.00002 14.6999C3.94993 14.6999 0.666687 11.4166 0.666687 7.36654ZM8.00002 4.0332C8.36821 4.0332 8.66669 4.33168 8.66669 4.69987V6.69987H10.6667C11.0349 6.69987 11.3334 6.99835 11.3334 7.36654C11.3334 7.73473 11.0349 8.0332 10.6667 8.0332H8.66669V10.0332C8.66669 10.4014 8.36821 10.6999 8.00002 10.6999C7.63183 10.6999 7.33335 10.4014 7.33335 10.0332V8.0332H5.33335C4.96516 8.0332 4.66669 7.73473 4.66669 7.36654C4.66669 6.99835 4.96516 6.69987 5.33335 6.69987H7.33335V4.69987C7.33335 4.33168 7.63183 4.0332 8.00002 4.0332Z" fill={{'default':'#CDD5E4', 'active':'#7929FF', 'hover':'#4D0CB9'}[plusRoundColor]}/>
        </svg>
    );
};


const _TB = ({ member, intl, mobile }) => {
    const {formatMessage} = intl;
    let navigate = useNavigate();
    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}/`, '');
    const click = () => { navigate(`/${lang}/experience-tickets`) };
    const text = formatMessage({id:'HEADER.PLOT_TICKETS'});
    const isActive = (postfix==='experience-tickets'?'active':'');
    const value = member.tickets !== undefined ? ticketFormat(member.tickets):<Skeleton width={'2rem'} />;
    const url = process.env.REACT_APP_TICKET_STORE ? process.env.REACT_APP_TICKET_STORE : '#';

    return mobile 
        ? <div className="mobile-balance" onClick={click}>
            <div className="mobile-balance-label">{text}:</div>
            <div className="mobile-balance-value">{value}</div>
        </div>
    
        : <button className={"clear py-2 px-0 nav-item-plot d-flex align-items-center " + isActive}>
            <span onClick={click}>{text}: <span className={"text-orange "}>{value}</span></span>
            <a href={ url } target="_blank" rel="noreferrer" className={"pe-0 ps-2 header--icon__plus"}>
                <PlusIcon />
            </a>
        </button>
}
const TicketButton =  connect(
    (state) => ({
        member:state.member,
        mobile:state.common.mobile,
    }),
    () => ({

    }),
)(injectIntl(_TB));

export default TicketButton;
