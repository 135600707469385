import {injectIntl} from "react-intl";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import TicketButton from './TicketButton';
import BalanceItem from "./BalanceItem";
import LinkedWalletMenuItem from "./LinkedWalletMenuItem";
import { addPromtElement, complitePromt } from "../../../actions";
import AW937HideElementsForCondition from "../../../components/crutches/AW937HideElementsForCondition";

const MobileBalance = ({
    promt,
    setMobileMenuShow,
    setShowSysWallet,
    setShowSysWalletToAbey,
    setShowTickets,
    setShowMobileAWBalancesModal,
    addPromtElement,
    complitePromt,
    intl,
   memberHasPlots
}) => {
    let {formatMessage} = intl;

    const awRef = useRef();
    const sysRef = useRef();
    const ticketRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            addPromtElement({
                element: awRef.current,
                promtName: 'mobileAw',
                header: formatMessage({id: "AW458.ABEY_BALANCE"}),
                body: formatMessage({id: "PROMT.ABEY_DESC"}),
                nextCallback: ()=> {
                    complitePromt('mobileAw');
                    setShowSysWalletToAbey(true);
                }
            });
            addPromtElement({
                element: sysRef.current,
                promtName: 'mobileSys',
                header: formatMessage({id: "AW458.SYSTEM_WALLET"}),
                body: formatMessage({id: "PROMT.SYSTEM_WALLET_DESC"}),
                nextCallback: ()=> {
                    complitePromt('mobileSys');
                    setShowSysWallet(true);
                }});
            addPromtElement({
                element: ticketRef.current,
                promtName: 'mobileTicket',
                header: formatMessage({id: "AW458.PLOT_TICKET_BALANCE"}),
                body: formatMessage({id: "PROMT.TICKET_DESC"}),
                nextCallback: ()=> {
                    complitePromt('mobileTicket');
                    setShowTickets(true);
                }

            });
        })
    }, []);

    useEffect(() => {
        if (promt.currentPromt) {
            setTimeout(function rec() {
                const promtActiveElement = document.querySelector('.promt-active-element');
                if (promtActiveElement) {
                    promtActiveElement.scrollIntoView();
                } else {
                    setTimeout(rec);
                }
            });
        }
    }, [promt.currentPromt]);

    return <div className="mobile-balance--container">
            <>
             <AW937HideElementsForCondition>
                <div className="mobile-balance--half_wrap mb-3">
                    <LinkedWalletMenuItem {...{setMobileMenuShow}} />
                </div>
            </AW937HideElementsForCondition>

                <div className="mobile-balance--half_wrap gap-3 mb-3">

                    <AW937HideElementsForCondition>
                        <div ref={awRef} onClick={()=> {
                            if (promt.complitedPromt.skipAll) {
                                complitePromt('mobileAw');
                                setShowMobileAWBalancesModal(true);
                            }
                        }}
                             className="flex-1"
                        >
                            <BalanceItem type="balance" label="ABEY" />
                        </div>
                    </AW937HideElementsForCondition>
                    <AW937HideElementsForCondition hide={!memberHasPlots}>
                        <div className="flex-1">
                            <BalanceItem type="points" label="AP" />
                        </div>
                    </AW937HideElementsForCondition>
                </div>
            </>

        <div className="mobile-balance--half_wrap mb-2 gap-3">

            <AW937HideElementsForCondition>
                <div className="flex-1" ref={sysRef} onClick={ ()=> {
                    if (promt.complitedPromt.skipAll) {
                        complitePromt('mobileSys');
                        setShowSysWallet(true);
                    }
                }}>
                    <BalanceItem type="sysWalletBalance" icon="faw-wallet " label={formatMessage({'id':'AW458.SYSTEM_WALLET'})}  />
                </div>
            </AW937HideElementsForCondition>

            <div ref={ticketRef} onClick={ ()=> {
                    if (promt.complitedPromt.skipAll) {
                        complitePromt('mobileTicket');
                    }
                }}
                className="flex-1"
            >
                    <TicketButton />
            </div>

        </div>

    </div>
}

export default connect(
    (state) => ({
      promt: state.promt,
      memberHasPlots: state.member.memberHasPlots
    }),
    (dispatch) => ({
        addPromtElement: (state) => {
            dispatch(addPromtElement(state));
        },
        complitePromt: (state) => {
            dispatch(complitePromt(state));
        },
    }),
)(injectIntl(MobileBalance));
