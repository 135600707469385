import {injectIntl} from '../../../services/injectIntlOrPolyfillIntl';

import cn from 'classnames';

const ICON = <svg xmlns="http://www.w3.org/2000/svg" width="88" height="89" viewBox="0 0 88 89" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M15.4801 15.98C22.775 8.68516 32.8634 4.16667 44 4.16667C55.1367 4.16667 65.225 8.68516 72.5199 15.98C79.8148 23.275 84.3334 33.3633 84.3334 44.5C84.3334 55.6367 79.8148 65.725 72.5199 73.0199C65.225 80.3148 55.1367 84.8333 44 84.8333C32.8634 84.8333 22.775 80.3148 15.4801 73.0199C8.1852 65.725 3.66669 55.6367 3.66669 44.5C3.66669 33.3634 8.18522 23.275 15.4801 15.98ZM29.3334 34.6458C29.3334 26.5457 35.8999 19.9792 44 19.9792C52.1002 19.9792 58.6667 26.5457 58.6667 34.6458C58.6667 41.4799 53.9925 47.2223 47.6667 48.8504V52.9792C47.6667 55.0042 46.0251 56.6458 44 56.6458C41.975 56.6458 40.3334 55.0042 40.3334 52.9792V45.6458C40.3334 43.6208 41.975 41.9792 44 41.9792C48.0501 41.9792 51.3334 38.6959 51.3334 34.6458C51.3334 30.5958 48.0501 27.3125 44 27.3125C39.95 27.3125 36.6667 30.5958 36.6667 34.6458C36.6667 36.6709 35.0251 38.3125 33 38.3125C30.975 38.3125 29.3334 36.6709 29.3334 34.6458ZM44 69.4792C46.5313 69.4792 48.5834 67.4271 48.5834 64.8958C48.5834 62.3646 46.5313 60.3125 44 60.3125C41.4687 60.3125 39.4167 62.3646 39.4167 64.8958C39.4167 67.4271 41.4687 69.4792 44 69.4792Z" fill="#F0A920"/>
</svg>

const ModalConfirm = ({intl, show, closeBtn = '', confirmBtn = '', handleClose, handleConfirm}) => {
    const { formatMessage } = intl;
    const confirmationContainerClasses = [
        'confirmation-container',
        'modal-logout',
    ];

    return !show
        ? null
        : ( <div className={cn(confirmationContainerClasses)}>
                <div className="confirmation-close-overlay" onClick={handleClose}></div>
                <div className="modal_success">
                    <div className="modal_success__wrapper">
                        <div className="modal_success__content">
                            <div className="modal_success__icon">
                                {ICON}
                            </div>
                            <div className="modal_success__body" style={{width: '100%'}}>
                                <div className="modal_success__title">{formatMessage({id:'COMMON.SURE'})}</div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_success__action">
                        <button className="redesign-btn btn-square ghost md" onClick={handleClose}>
                            {closeBtn || formatMessage({'id':'COMMON.CLOSE'})}
                        </button>
                        <button className="redesign-btn btn-square filled md" onClick={handleConfirm}>
                            {confirmBtn || formatMessage({'id':'COMMON.YES'})}
                        </button>
                    </div>
                </div>
            </div> );
};

export default injectIntl(ModalConfirm);
