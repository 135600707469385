import {Navigate} from "react-router-dom";
import { setOtp, setLoginProcessGo, setMemberId, skipAllPromt } from '../../actions';
import { connect } from 'react-redux';

const LoginByOtp = ({setLoginProcessGo, setMemberId, setOtp, skipAllPromt}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const otp = urlParams.get('otp'),
        memberId = urlParams.get('member_id'),
        lang = urlParams.get('lang') ?? 'en';
    skipAllPromt();
    setOtp(otp);
    setMemberId(memberId)
    setLoginProcessGo(true);

    return (<Navigate to={`/${lang}/loader`} />);
};

function mapStateToProps(state) {
    return {
        loginProcess: state.login.loginProcess,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setLoginProcessGo: (isGo) => {
            dispatch(setLoginProcessGo(isGo));
        },
        setOtp: (otp) => {
            dispatch(setOtp(otp));
        },
        setMemberId: (memberId) => {
            dispatch(setMemberId(memberId));
        },
        skipAllPromt: (state) => {
            dispatch(skipAllPromt(state));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginByOtp);
