import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";

import {
    setShowModalAbeyWallet
} from '../../../actions';

const ItemIcon = ({absent, added}) => {
    const iconClasses = [
        '',
        !absent ? (added ? 'faw-clock':'faw-check_round text-green') : 'faw-cross_round text-red',
    ];
    return (<i className={iconClasses.join(' ')} style={{fontSize: '24px'}}></i>);
};

const LinkedWalletMenuItem = ({intl, mobile, member, setMobileMenuShow, setShowModalAbeyWallet}) => {
    const absent = member.addressStatus === 'absent';
    const added = member.addressStatus === 'added';
    const confirmed = member.addressStatus === 'confirmed';

    const {formatMessage} = intl;

    const itemClasses = [
        'link__linked-wallet',
        !absent ? (added ? 'notconfirmed':'valid') :'invalid',
    ];

    return (
        member.addressStatus &&  (
            mobile
                ? 
                        <div className="mobile-balance d-flex w-100 align-items-center"  style={{padding: '12px 16px'}}
                            onClick={(e) => { e.preventDefault(); setShowModalAbeyWallet(true); }}
                        >
                            <div className="mobile-balance-label">{formatMessage({id:'PLOT_PROFILE.ABEY_2_WALLET'})} :</div>
                            <div className="mobile-balance--lw-value ms-auto d-flex">
                                <ItemIcon {...{absent, added, confirmed}} />
                            </div>
                        </div>
                    
                : <Dropdown.Item
                    onClick={(e) => { e.preventDefault(); setShowModalAbeyWallet(true); }}
                    className={itemClasses.join(' ')}
                    href={`#`}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {formatMessage({id:'PLOT_PROFILE.ABEY_2_WALLET'})}
                        </div>
                        <ItemIcon {...{absent, added, confirmed}} />
                    </div>
                </Dropdown.Item>
        )
    );
};

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
    }),
    (dispatch) => ({
        setShowModalAbeyWallet: (showModalAbeyWallet) => {
            dispatch(setShowModalAbeyWallet(showModalAbeyWallet));
        },
    }),
)(injectIntl(LinkedWalletMenuItem));
