import {useEffect, useRef, useState} from "react";
import {  Form } from "react-bootstrap";
import {injectIntl} from "react-intl";
import {Link, useParams} from "react-router-dom";
import IntlTelInput from '../../components/react-intl-tel-input/src';
import '../../components/react-intl-tel-input/src/intlTelInput.scss';
import './start.scss';
import registrationService from '../../api/registration';
import Skeleton from "react-loading-skeleton";
import {
    setRegistrationPhoneToSend,
    setRegistrationEmailToSend,
    setRegistrationPhoneToRender,
    setRegistrationDecaySeconds,
    setRegistrationAvailableIn,
    setRegistrationPhoneCountry,
} from '../../actions';
import {connect} from "react-redux";
import { setCaretPosition } from '../../services/utils';
import  FormInput from '../../components/FormInput';

const Start = ({intl, setRegistrationStep, setPreviousStep, phoneToRender, confirmType, setConfirmType, setPhoneToSend, setEmailToSend, setPhoneToRender, setPhoneCountry, setDecaySeconds, setAvailableIn, }) => {
    const { lang } = useParams();

    const [error, setError] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const inputTel = useRef(null);
    const inputEmail = useRef(null);
    const [phone, setPhone] = useState(phoneToRender.length ? phoneToRender:'+86');
    const [isCodeRequested, setIsCodeRequested] = useState(false);
    const {formatMessage} = intl;

    const [defaultCountryCode, setDefaultCountryCode] = useState(null);
    useEffect(() => {
        const controller = new AbortController();
        const {signal} = controller;
        registrationService.getGeoIp(signal).then(resp => resp.json()).then(({countryCode}) => {
            setDefaultCountryCode(countryCode ? countryCode.toLowerCase() : 'cn');
        }).catch(err => {
            console.log('geoIp error', err);
            setDefaultCountryCode('cn');
        });
    
      return () => controller.abort();
    }, [])
    

    const handleSendCodeSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const values = Object.fromEntries(data.entries());
        const phone = confirmType === 'phone' ? values.phone.replace(/(\s|\+)/g, '') : null;
        const email = confirmType === 'email' ? values.email.trim() : null;
        setIsCodeRequested(true);
        const phoneCountry = confirmType === 'phone' ? inputTel.current.selectedCountryData.iso2.toUpperCase() : '';
        setPhoneCountry(phoneCountry);

        registrationService.confirmSend(
            phone,
            email,
            phoneCountry,
            lang
        )
            .then(resp => resp.json())
            .then((data) => {
                setPhoneToSend(phone);
                setEmailToSend(email);
                setDecaySeconds(data.decaySeconds);
                setAvailableIn(data.availableIn);

                setIsCodeRequested(false);
                setRegistrationStep('confirmNumber');
                setPreviousStep('start');
            })
            .catch(err => {
                setIsCodeRequested(false);
                if (!err.response) {
                    setError(phone 
                        ? {phone :formatMessage({id:'validation.toomany'})}
                        : {email :formatMessage({id:'validation.toomany'})}
                    )
                } else {
                    err.response
                        .json()
                        .then(data => {
                            setError({
                                phone: data.errors.phone ? data.errors.phone.map(item => formatMessage({id:item}, {'attribute':formatMessage({id:'COMMON.PHONE'}).toLowerCase()})).join(' ') : null,
                                email: data.errors.email ?? null
                            });
                        })
                }
            });
    };
    const onPhoneFieldChange = (isValid, value, selectedCountryData, fullNumber, extension, event) => {
        let render = value === ''
            ? '+'
            : (isNaN(parseInt(value)) ? '+':`+${parseInt(value)}`);

        let splited = fullNumber.split(' ');
        if (isValid) {
            render = splited.join(' ').split('-').join(' ');
            setPhoneToRender(render);
        }
        setPhone(render);
        setIsValidPhone(isValid);

        if (isValid) {
            inputTel.current.tel.blur();
            setTimeout(() => {
                document.querySelector('button[type="submit"]').focus();
            }, 0)
        }

        if (render === '+') {
            setTimeout(() => {
                setCaretPosition(inputTel.current.tel, render.length);
            }, 0);
        }
        if (render.toString().match(/\e|\./)) {
            setError({phone: formatMessage({id:'validation.phone_exist'})});
        } else {
            if (isValid) {
                setError('');
            }
        }
    };
    const onFlagSelect = () => {
        setTimeout(() => {
            setCaretPosition(inputTel.current.tel, inputTel.current.tel.value.length);
        }, 200);
    };

    const onEmailChange = (evt) => {
        setIsValidEmail(evt.target.value && (evt.target.validity.valid ?? false));
    }

    useEffect(() => {
        if (confirmType === 'email') {
            inputEmail.current.focus();
        }
    }, [confirmType])

    return (
        <Form onSubmit={handleSendCodeSubmit}>
            <h5>{formatMessage({'id':"REGISTRATION.CREATE_NEW_ACCOUNT"})}</h5>


                <div className="d-flex align-items-center">
                    <Form.Check type="radio"
                        className="me-2 mt-4"
                        name="group"
                        value="phone"
                        onClick={()=>setConfirmType("phone")}
                        defaultChecked={confirmType === "phone"}
                    />
                    <Form.Group className={"flex-1" + ((error && error?.phone) ? "is-invalid":"")} controlId="formBasicPhone">
                        <Form.Label>{formatMessage({'id':"REGISTRATION.ENTER_PHONE"})}</Form.Label>

                        <IntlTelInput
                            ref={inputTel}
                            value={phone}
                            onPhoneNumberChange={onPhoneFieldChange}
                            onSelectFlag={onFlagSelect}
                            fieldName={"phone"}
                            containerClassName="intl-tel-input w-100"
                            inputClassName="form-control w-100"
                            autoFocus={true}
                            nationalMode={false}
                            separateDialCode={false}
                            preferredCountries={['cn', 'hk', 'mo', 'tw', 'jp', 'kr', 'ca', 'us', 'ru']}
                            disabled={confirmType !== "phone" || defaultCountryCode === null}
                            defaultCountry={defaultCountryCode}
                        />
                        { !(error && error?.phone) ? null : <div className={"form-control-error"}>{error?.phone}</div> }
                    </Form.Group>
                </div>
                <div className="d-flex align-items-center or-divider w-100">
                    <div className="divider flex-1" />

                    <div className="mx-2 mt-3 or" >{formatMessage({'id':"REGISTRATION.OR"})}</div>
                    <div className="divider flex-1" />
                </div>

                <div className="d-flex align-items-center">
                    <Form.Check
                        type="radio"
                        className="me-2 mt-4"
                        name="group"
                        value="email"
                        onClick={()=>setConfirmType("email")}
                        defaultChecked={confirmType === "email"}
                    />
                    <FormInput
                        {...{
                            groupClassName: 'w-100',
                            controlId: 'formBasicEmail',
                            label: formatMessage({'id':"REGISTRATION.ENTER_EMAIL_REQ"}),
                            controlType: 'email',
                            controlName: 'email',
                            autoFocus:true,
                            placeholder: formatMessage({'id':"COMMON.EMAIL"}),
                            error: error.email,
                            onControlChange: onEmailChange,
                            errorParams: {
                                attribute: formatMessage({'id':"COMMON.EMAIL"})
                            },
                            refInput: inputEmail,
                            disabled: confirmType !== "email"
                        }}
                    />

                </div>
            {
                isCodeRequested
                    ? <Skeleton width={"413px"} height={"48px"} className="w-100 mt-4" ></Skeleton>
                    : <>

                        <button 
                            className={"redesign-btn btn-square filled w-100 mt-4"}
                            disabled={confirmType ==='phone' ? !isValidPhone : !isValidEmail}
                            type="submit"
                        >
                            {formatMessage({'id':"COMMON.NEXT"})}
                        </button>
                    </>
            }

            <div className="form-text mt-4">
                {formatMessage({'id':"REGISTRATION.ALREADY_HAVE_ACCOUNT"})} <Link to={`/${lang}/login`}>{formatMessage({'id':"REGISTRATION.SIGN_IN"})}</Link>
            </div>
        </Form>
    );
};

export default connect(
    (state) => ({
        phoneToRender: state.registration.phoneToRender,
    }),
    (dispatch) => ({
        setPhoneToSend: (phone) => {
            dispatch(setRegistrationPhoneToSend(phone));
        },
        setEmailToSend: (phone) => {
            dispatch(setRegistrationEmailToSend(phone));
        },
        setPhoneToRender: (phone) => {
            dispatch(setRegistrationPhoneToRender(phone));
        },
        setDecaySeconds: (decaySeconds) => {
            dispatch(setRegistrationDecaySeconds(decaySeconds));
        },
        setAvailableIn: (availableIn) => {
            dispatch(setRegistrationAvailableIn(availableIn));
        },
        setPhoneCountry: (phoneCountry) => {
            dispatch(setRegistrationPhoneCountry(phoneCountry));
        },
    })
)(injectIntl(Start));
