/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useState, useMemo, useEffect } from 'react';
import cn from "classnames";
import styles from "../assets/styles/vlands/top.module.scss";
import Portal from "../../../../components/Portal";
import MapLand from "../../../../components/MapLand";
import NavBar from './NavBar';
import videoHomeBG from "../assets/homebg.mp4";
import imgPoster from "../assets/poster.webp";

// import MobileHeader from '../../landing/MobileHeader';

import imgWindows from "../assets/windows.svg";

import imgMember1 from "../assets/unsplash_XttWKETqCCQ.png";
import imgMember2 from "../assets/unsplash_QDq3YliZg48.png";
import imgMember3 from "../assets/unsplash_lkMJcGDZLVs.png";
import imgMember4 from "../assets/unsplash_HD8KlyWRYYM.png";

import imgIconArrowRightUp from "../assets/arrow.svg";
import { DOWNLOAD_LINK } from "etc/define";

const TopBanner = ({intl, logged, isShort=false}) => {
  const { lang } = useParams();
  const {formatMessage} = intl;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
    return (<>

            {/* <div className={cn(styles.top_bg)}></div> */}
            <video muted loop autoPlay className={cn(styles.top_bg_video)} src={videoHomeBG} poster={imgPoster}/>
            <div className={cn(styles.top)}>
              <NavBar />
              {!isShort && <div className={cn(styles["title"])}>
                <div className={cn(styles.first_title)}>{formatMessage({'id': 'AW-980.LANDING_TITLE'})}</div>


                  <div className={cn(styles["button"])}>
                    <Link to={`/${lang}/registration`} className={cn(styles["button2"])}>
                      <div className={cn(styles["join-to-metaverse"])}>{formatMessage({'id': 'LANDING.JOIN'})}</div>
                      <img className={cn(styles["arrow-right"])} src={imgIconArrowRightUp} alt="arrow" />
                    </Link>

                    <div className={cn(styles["join"])}>
                      <div className={cn(styles["frame-4111"])}>
                        <div style={{display: 'none'}} className={cn(styles["group-28"])}>
                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember1} alt='Member'
                          />

                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember2} alt='Member'
                          />

                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember3} alt='Member'
                          />

                          <img
                            className={cn(styles["unsplash"])}
                            src={imgMember4} alt='Member'
                          />
                        </div>
                        <div className={styles['circle-members-joined']}></div>

                        <div className={cn(styles["_200-members-joined"])}>{formatMessage({'id': 'LANDING.JOINED'})}</div>
                      </div>
                    </div>
                  </div>
                  <div className={cn(styles["top_btn__container"])}>
                    <a className={cn(styles["top_btn__download"])} href={DOWNLOAD_LINK} download>
                      <span>{formatMessage({'id': 'DOWNLOAD.FOR_WINDOWS'})}</span>
                      <img src={imgWindows} alt="win" />
                    </a>
                  </div>
              </div>}
            </div>
            <Portal wrapperId='top-modal'>
              <MapLand {...{show, onHide:handleClose}}/>
            </Portal>
          </>);
};

export default connect(
    (state)=>({
      logged: !!state.member.id,
      // mobile: state.common.mobile,
    }),
    ()=>({

    })
)(injectIntl(TopBanner));
