import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { abeyFormat, ticketFormat } from "../../../utils/numberFormat";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

const  getFormat = (type) => {
    switch (type) {
        case 'tickets':
            return ticketFormat;
        default:
            return abeyFormat;
    }
}

const BalanceItem = ({mobile, member, type, label, icon, innerRef, hideCross = false}) => {
    const text = member[type] === null ? 'N/A' : (member[type] !== undefined ? getFormat(type)(member[type]) : <Skeleton count={1} width='2rem' />) ;

    return mobile
        ? (
            <div className="mobile-balance" ref={innerRef}>
                {label && <div className="mobile-balance-label">{label}</div>}
                <div className="mobile-balance-value  d-inline-flex">{text}</div>
            </div>
        )
        : (
            <div className={"nav-item-balance d-flex align-items-center btn p-0 " + type} ref={innerRef}>
                {icon && <i className={`${icon} d-block`}></i>}<span className={"d-block ms-1"}>{label} <span>{text}</span></span>
                {!hideCross && <i className="faw-cross_round sm-icon rotate-45 bg-gray"></i>}
            </div>
        );
};

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
    }),
    (dispatch) => ({}),
)(injectIntl(BalanceItem));
