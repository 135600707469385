import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Portal from "../../../components/Portal";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {skipAllPromt} from "../../../actions";

const ModalComplitePromt = ({intl, mobile,  onClose, show, skipAllPromt}) => {
    const { formatMessage } = intl;
    const navigate = useNavigate();
    const { lang } = useParams();

    const onKeyDownHandler = (event) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler);
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
        };
    }, []);

    useEffect(() => {
        if (show) 
            skipAllPromt();
    }, [show]);

    return (<>
        {show &&<Portal wrapperId="modal-success">
            <div className="confirmation-container modal-abey-wallet">
                <div className="confirmation-close-overlay"  onClick={() => onClose()}></div>
                <div className="modal_success">
                    <div className="modal_success__wrapper">
                        <div className="modal_success__content text-center">
                            <div className="modal_success__icon">
                                <i className={'faw-check_round text-green'}></i>
                            </div>
                            <div className="modal_success__body">
                                <div className="modal_success__title mb-3">{formatMessage({"id":"PROMT.WELCOME_TITLE"})}</div>
                                <div className="market-buildings--congratulations-message"  dangerouslySetInnerHTML={{__html:  formatMessage({id:"PROMT.WELCOME_DESC"})}}></div>
                            </div>
                        </div>
                    </div>
                    <div className="modal_success__action ">
                        <button className="redesign-btn btn-square ghost md w-100" onClick={()=> { navigate(`/${lang}`); onClose();}} >
                            {formatMessage({'id':'PROMT.GO_HOME'})}
                        </button>
                        <button className="redesign-btn btn-square filled md w-100" onClick={() => onClose()}>
                            {formatMessage({'id':'PROMT.STAY'})}
                        </button>
                    </div>
                </div>
            </div>
        </Portal>}
    </>
    );
};

export default connect(
    (state) => ({
        mobile: state.common.mobile,
    }),
    (dispatch) => ({
        skipAllPromt: (state) => {
            dispatch(skipAllPromt(state));
        },
    }),
)(injectIntl(ModalComplitePromt));
