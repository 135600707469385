import {Dropdown, Nav} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

import {setGoldrushMainstate, setLyState, setMarketplaceDefault} from "../../../actions";
import AW937HideElementsForCondition from "../../../components/crutches/AW937HideElementsForCondition";

import { BASE_DOMAIN } from "etc/define";

const HeaderFullMenu = ({intl, setGoldrushMainstate, member, setMarketplaceDefault, yesNetCallback, memberHasPlots, _2DAccess}) => {
    const {formatMessage} = intl;
    let navigate = useNavigate();
    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}/`, '');

    return (<>
        <AW937HideElementsForCondition hide={!memberHasPlots}>
            <Nav.Link  active={[`marketplace`].indexOf(postfix) !== -1} onClick={() => {navigate(`/${lang}/marketplace`); setMarketplaceDefault()}}>{formatMessage({id:'LINK.MARKETPLACE'})}</Nav.Link>
        </AW937HideElementsForCondition>
        <AW937HideElementsForCondition>
            <>
                <Dropdown>
                    <Dropdown.Toggle variant={"header"} className={['game', `gold-rush`, `wow`, 'lumber-yard'].indexOf(postfix) !== -1 ? "active":''}>
                        {formatMessage({id:'HEADER.GAMES'})}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={(e) => { e.preventDefault(); navigate(`/${lang}/game-center`); setGoldrushMainstate('default'); }}
                            className={[`game-center`].indexOf(postfix) !== -1 ? "active":''}
                        >{formatMessage({id:'MARKETPLACE.GAME_CENTER'})} </Dropdown.Item>
                        <Dropdown.Item
                            href={`https://xracer.game/`}
                            target="_blank"> XRacer </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(e) => { e.preventDefault(); navigate(`/${lang}/gold-rush`); setGoldrushMainstate('default'); }}
                            className={[`gold-rush`].indexOf(postfix) !== -1 ? "active":''}
                        >{formatMessage({id:'HEADER.GOLD_RUSH'})} </Dropdown.Item>
                        <Dropdown.Item
                            onClick={(e) => { e.preventDefault(); navigate(`/${lang}/lumber-yard`); }}
                            className={[`lumber-yard`].indexOf(postfix) !== -1 ? "active":''}
                        >{formatMessage({id:'HEADER.LUMBER_YARD'})} </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        </AW937HideElementsForCondition>
        { _2DAccess && <Nav.Link href={`https://2d.${BASE_DOMAIN}/`} target="_blank" rel="noreferrer">
            <div className="d-inline-block">
                <div>{formatMessage({id:'HEADER.2D'})}</div>
            </div>
        </Nav.Link>}


        {member.adnetIsVisible && <Nav.Link  active={postfix.indexOf(`ads-`) !== -1}   onClick={() => {navigate(`/${lang}/ads-overview`); setMarketplaceDefault()}}>{formatMessage({id:'HEADER.ADS'})}</Nav.Link>}

        <Nav.Link  active={!!(postfix.match(/^support\/(all|open|in-progress|closed)/) || [`announcements`, 'events', 'tutorials'].indexOf(postfix) !== -1)} className="d-flex align-items-center" onClick={() => {navigate(`/${lang}/announcements`)}}>
            {formatMessage({id:'HEADER.SUPPORT'})}
            {!![member.unreadEvents, member.unreadAnnouncements].filter(item => item).length && <div className={"unread--marker ms-1"}></div>}
        </Nav.Link>
        <Nav.Link  active={postfix.indexOf('downloads') !== -1} className="d-flex align-items-center" onClick={() => {navigate(`/${lang}/downloads`)}}>
            {formatMessage({id:'LINK.DOWNLOADS'})}
        </Nav.Link>
    </>);
};

export default connect(
    (state) => ({
        member: state.member,
        isProd: state.common.isProd,
        memberHasPlots: state.member.memberHasPlots,
        _2DAccess: state.member._2DAccess,
    }),
    (dispatch) => ({
        setGoldrushMainstate: (step) => {
            dispatch(setGoldrushMainstate(step));
        },
        setLumberYardMainstate: (step) => {
            dispatch(setLyState(step));
        },
        setMarketplaceDefault: (state) => {
            dispatch(setMarketplaceDefault(state));
        },
    }),
)(injectIntl(HeaderFullMenu));
