import {
    SET_SHOW_ERROR_MODAL,
    APPEND_ERROR, CHANGE_ERROR_LIST,
} from '../../actions';

const initialState = {
    showModalError: false,
    text: '',
    list: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOW_ERROR_MODAL:
            return {...state, showModalError: action.payload}
        case APPEND_ERROR:
            let {list} = state;
            list.push(action.payload);
            list = list.filter((v, i, s) => s.indexOf(v) === i)
            return {...state, list, showModalError: true}
        case CHANGE_ERROR_LIST:
            return {...state, list:action.payload, showModalError: !!action.payload?.length}
        default:
            return state;
    }
};
