import {useEffect, useRef, useState} from "react";
import {OverlayTrigger} from "react-bootstrap";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {numberFormatterFactory, abeyFormat} from "../../../utils/numberFormat";
import FormInput from "../../../components/refactoring/FormInput";
import memberService from "../../../api/member";
import {getMoneyValue} from "../../../services/utils";
import {
    setBalance,
    setSysWalletBalance,
    skipAllPromt,
    changeCrossSuccessList
} from "../../../actions";
import Modal from "../../../components/refactoring/ModalPage";

import cn from "classnames";

const ModalSysWalletToAbey = ({intl, member,  mobile,  onClose, show, setBalance, setSysWalletBalance, promt, skipAllPromt, changeCrossSuccessList}) => {
    const { formatMessage } = intl;

    const abeyRef = useRef();
    const [copied, setCopied] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [error, setError] = useState(false);
    const [abey, setAbey] = useState(1);
    const [limits, setLimits] = useState(null);
    const numberFormater = numberFormatterFactory(0, 0);

    const isPromt = !promt.complitedPromt.skipAll && (!promt.complitedPromt.ticket || !promt.complitedPromt.mobileTicket);

    useEffect(() => {
        const idTooltip = setTimeout(() => {
            setShowTooltip(false);
        }, 2000);
        const idCopied = setTimeout(() => {
            setCopied(false);
        }, 2500);
        return () => {
            clearTimeout(idTooltip);
            clearTimeout(idCopied);
        }
    }, [copied])

    useEffect(() => {
        show && memberService
            .getAbeyTansferForm()
            .then(response => response.json())
            .then(result => {
                setLimits(result);
                handleUpdateABEY();
            })
            .catch(() => {
                setError(true);
            });

    }, [show]);

    const [inProcess, setInProcess] = useState(false);

    const transfer = () => {
        setInProcess(true);
        memberService
            .setAbeyTansfer(abey)
            .then(response => response.json())
            .then(result => {
                memberService.sysBalance()
                    .then(response => response.json())
                    .then((cmlxBalanceData) => {
                        setSysWalletBalance(getMoneyValue(cmlxBalanceData.balance));
                    });
                memberService.cmlxBalance()
                    .then(response => response.json())
                    .then((cmlxBalanceData) => {
                        setBalance(getMoneyValue(cmlxBalanceData.balance));
                    });
                changeCrossSuccessList([{failed: false, text: formatMessage({id:"AW458.ABEY_DEPOSITED_ABEY"}, {abey: `<strong>${abey}</strong>`})}]);
                onCloseModal();
            }).catch(() => {
                setError(true);
                
            })
            .finally(() => {
                setInProcess(false);
            });
    }

    const onCopyOver = () => {
        if(!showTooltip) {
            setShowTooltip(true);
        }
    };
    const onCopyOut = () => {
        if(showTooltip && !copied) {
            setShowTooltip(false);
        }
    };
    const onCopyClick = () => {
        setCopied(true);
        setShowTooltip(true);
        window.navigator.clipboard.writeText(member.sysWalletAddress ?? "N/A");
    };

    const checkKeyDownEvent = (event, regexp) => {
        if (['Backspace', 'Enter', 'Tab', 'Delete'].indexOf(event.key) !== -1) {
            return true;
        }
        if (event.key && event.key.search("Arrow") !== -1) {
            return true;
        }
        if (event.ctrlKey) {
            return true;
        }
        return event.key.match(regexp);
    }

    const handleKeyDownABEY = (event) => {
        const value = abeyRef.current.value,
        valueSplitted = value.split('.');
        if (! checkKeyDownEvent(event, /[0-9]/)
            || (event.key.match(/[0-9]/) && valueSplitted[1] && valueSplitted[1].length > 0)
        ) {
            event.preventDefault();
        } else {
            setTimeout(() => {
                handleUpdateABEY(abeyRef.current.value);
            });
        }
    };

    const handleUpdateABEY = (value) => {
        value = value ? value.replace(',', '') : 0;
        value = parseInt(value);
        if (isNaN(value)) {
            value = 0;
        }
        if (limits && parseInt(limits.maxAvailable.decimal) < value) {
            value = parseInt(limits.maxAvailable.decimal);
        }
        const formatted = numberFormater.format(value);
        abeyRef.current.value = formatted;
        setAbey(formatted.replace(/[,\s]/gi, ''));
    };

    const onKeyDownHandler = (event) => {
        if (event.key === 'Escape') {
            onCloseModal();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler);
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
        };
    }, []);

    const onCloseModal = () => {
        setError(false);
        onClose();
    }

    return (<>
        <Modal.Container  show={!!show} onClose={onClose} className={cn({'promt-content ': isPromt})}>
            <Modal.Header > {formatMessage({id:"AW458.TOP_UP_ABEY_BALANCE"})}</Modal.Header>
            
            <Modal.Body>
                <div
                    className={"description"}
                    dangerouslySetInnerHTML={{__html: formatMessage({id:'AW458.TOP_UP_ABEY_BALANCE_DESC'})}}
                ></div>

                <div className="marketplace-sell-info w-100">
                    <div className='description'><strong>{formatMessage({id: 'MARKETPLACE.FROM'})}</strong></div>

                    <div className="label mb-1 mt-3">
                        {formatMessage({'id':'AW458.SYSTEM_WALLET_ADDRES'})}:
                    </div>
                    <div className="value">
                        <div className="d-flex">
                            <div className={cn("flex-1 me-2", (mobile ? 'redesign-text-break-all' : 'redesign-text-ellipsis'))} >
                                {member.sysWalletAddress ?? "N/A"}
                            </div>
                            <div {...{onMouseOver:onCopyOver, onMouseOut:onCopyOut}}>
                                <OverlayTrigger
                                    placement={'left'}
                                    trigger={['click', 'hover']}
                                    show={showTooltip}
                                    overlay={ ({ arrowProps, placement, ref, style }) => {
                                        return (
                                            <div className="tooltip me-2" ref={ref} style={style}>
                                                <span className="tooltip-inner" dangerouslySetInnerHTML={{__html: formatMessage({id:copied ? 'COMMON.COPIED':'COMMON.COPY'})}} />
                                            </div>
                                        );
                                    }
                                    }>
                                    <div className="">
                                        
                                        <i className="faw-copy cursor-pointer" onClick={onCopyClick}  />
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>

                    <div className="label mb-1 mt-3">
                        {formatMessage({'id':'AW458.SYSTEM_WALLET_BALANCE'})}:
                    </div>
                    <div className="value">
                        <i className="faw-wallet text-green me-1"></i>{(member.sysWalletBalance >= 0) ? abeyFormat(member.sysWalletBalance) : 'N/A'}
                    </div>

                </div>
                    
                <div className="marketplace-sell-action w-100">
                    <div className='description'><strong>{formatMessage({id: 'MARKETPLACE.TO'})}</strong></div>
                    <div className="label mb-1 mt-3">
                        {formatMessage({'id':'AW458.ABEY_BALANCE'})}:
                    </div>
                    <FormInput
                        refInput={abeyRef}
                        size="md"
                        controlType={"text"}
                        groupClassName={""}
                        controlId={"abey"}

                        defaultValue={0}
                        onControlBlur={event => handleUpdateABEY(event.target.value)}
                        onKeyDown={handleKeyDownABEY}
                        max={limits ? +limits.maxAvailable.decimal : 9999999}
                        min={0}
                        disabled={inProcess || isPromt || !limits || !member.sysWalletAddress || member.balance === undefined}
                        error={error && formatMessage({'id':"MARKETPLACE.ERROR_SOMETHING_WENT_WRONG"})}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                {isPromt 
                    ? <>
                        <button className='redesign-btn btn-square outline-common' 
                            onClick={() => {
                                skipAllPromt();
                                onClose();
                            }}

                        >
                            {formatMessage({id:"COMMON.SKIP"})}
                        </button>
                        <button
                            className='redesign-btn btn-square filled'
                            onClick={onClose}
                            >
                                {formatMessage({id:"COMMON.NEXT"})}
                        </button>
                    </>

                    : <>
                        <button className='redesign-btn btn-square outline-common'
                            onClick={onClose}
                        >
                            {formatMessage({id:"COMMON.CANCEL"})}
                        </button>
                        <button
                            className='redesign-btn btn-square filled'
                            onClick={transfer}
                            disabled={error || !limits || inProcess || !member.sysWalletAddress  || (+abey < 1) || (+abey > +limits.maxAvailable.decimal)}
                            >
                               {formatMessage({id:"COMMON.CONFIRM"})}
                        </button>
                    </>
                }

            </Modal.Footer>
        </Modal.Container>
    </>);
};

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
        promt: state.promt,
    }),
    (dispatch) => ({
        setBalance: (address) => {
            dispatch(setBalance(address));
        },
        setSysWalletBalance: (address) => {
            dispatch(setSysWalletBalance(address));
        },
        skipAllPromt: (state) => {
            dispatch(skipAllPromt(state));
        },
        changeCrossSuccessList: (info) => {
            dispatch(changeCrossSuccessList(info));
        }
    }),
)(injectIntl(ModalSysWalletToAbey));
