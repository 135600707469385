import { injectIntl } from "react-intl";
import { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { Nav } from 'react-bootstrap';
import {useNavigate, useParams} from "react-router-dom";
import {
    setMarketplaceContentType,
    setMarketplaceSelectedItem
} from "../../actions";

import ResourceList from '../../routes/marketplace/ResourceList';
import Skeleton from "react-loading-skeleton";
import AW937HideElementsForCondition from "../../components/crutches/AW937HideElementsForCondition";

const MarketplaceNav = ({intl, setContentType, setSelectedItem, contentType, member, isProd, myMinerals, myWood, isAllowedVB}) => {
    const mounted = useRef(false);
    useEffect(() => {
          mounted.current = true; // Will set it to true on mount ...
          return () => { mounted.current = false; }; // ... and to false on unmount
    }, []);

    const { formatMessage } = intl;
    let navigate = useNavigate();
    const { lang } = useParams();

    const isCategory3DVisible = (process.env?.REACT_APP_MARKETPLACE_3D_VISIBLE_FOR ?? '').split(',')
        .map(item => parseInt(item))
        .indexOf(member.id) !== -1;

    let buyCategoryItems = [];

    if (isCategory3DVisible) {
        buyCategoryItems.unshift({type: 'pets',         labelId: {'id':"MARKETPLACE.PETS"},         soon: false});
        buyCategoryItems.unshift({type: 'plants_trees', labelId: {'id':"MARKETPLACE.PLANTS_TREES"}, soon: false});
        buyCategoryItems.unshift({type: 'animation',    labelId: {'id':"MARKETPLACE.ANIMATION"},    soon: false});
        buyCategoryItems.unshift({type: 'furniture',    labelId: {'id':"MARKETPLACE.FURNITURES"},   soon: false});
        buyCategoryItems.unshift({type: 'avatars',      labelId: {'id':"MARKETPLACE.AVATARS"},      soon: false});

        buyCategoryItems.unshift({type: 'premium-buildings', labelId: {'id':"ASSET.PREMIUM_BUILDING"}, soon: false})
    }
    buyCategoryItems.unshift({type: 'buildings', labelId: {'id':"MARKETPLACE.BUILDINGS"}, soon: false});
    buyCategoryItems.unshift({type: 'vehicles', labelId: {'id':"MARKETPLACE.3D"}, soon: false})


    const actionNavs = [
        {type: 'buy', labelId: {'id':"MARKETPLACE.MARKET"}, items: buyCategoryItems},

        {type: 'sell', labelId: {'id':"MARKETPLACE.ASSETS"}, items: [
            {type:"vehicles", labelId: {'id':"MARKETPLACE.3D"}, soon: false},
            {type: 'buildings', labelId: {'id':"MARKETPLACE.BUILDINGS"}, soon: false},
            (isCategory3DVisible ? {type:"premium-buildings", labelId: {'id':"ASSET.PREMIUM_BUILDING"}, soon: false} : null),

            (isCategory3DVisible ? {type: 'avatars',      labelId: {'id':"MARKETPLACE.AVATARS"},      soon: false} : null),
            (isCategory3DVisible ? {type: 'furniture',    labelId: {'id':"MARKETPLACE.FURNITURES"},   soon: false} : null),
            (isCategory3DVisible ? {type: 'animation',    labelId: {'id':"MARKETPLACE.ANIMATION"},    soon: false} : null),
            (isCategory3DVisible ? {type: 'plants_trees', labelId: {'id':"MARKETPLACE.PLANTS_TREES"}, soon: false} : null),
            (isCategory3DVisible ? {type: 'pets',         labelId: {'id':"MARKETPLACE.PETS"},         soon: false} : null),

            (isAllowedVB ? {type: 'virtualbuildings', labelId: {'id':"MARKETPLACE.VIRTUAL_BUILDINGS"}, soon: false}:null),
            {type: 'minerals', labelId: {'id':"MARKETPLACE.MINERALS"}, soon: false},
            {type: 'wood', labelId: {'id':"MARKETPLACE.WOOD"}, soon: false},

            (isCategory3DVisible ? {type:"history", labelId: {'id':"ASSETSHISTORY.TITLE"}, soon: false} : null),
            {type: 'exchangeupgradehistory', nav: 'marketplace/exchangeupgradehistory', labelId: {'id':"MARKETPLACE.EU_HISTORY"}, soon: false},
            {type: 'bulding-purchase-history', nav: 'marketplace/bulding-purchase-history', labelId: {'id':"MARKETPLACE.BP_HISTORY"}, soon: false},
        ].filter(item => item)}
    ];
    const navigateCallback = (action, item) => {
        setContentType(action.type + '-' + item.type);
        // navigate(`/${lang}/${item.nav ?? 'marketplace'}`, {replace: true});
        navigate(`/${lang}/${item.nav ?? 'marketplace'}`);
    }

    useEffect(() => {
        localStorage.setItem('marketplace-type', contentType);
        setSelectedItem(null);
    }, [contentType]);

    const [myMaterials, setMyMaterials] = useState(null);

    useEffect(() => {
        if (myWood && myMinerals)
            setMyMaterials([...myMinerals, ...myWood])
        else
            setMyMaterials(null)
    }, [myWood, myMinerals]);

    return (<>
        {actionNavs.map((action, idAction) => (<div className='d-flex flex-column ' key={idAction}>
            <div className='add-nav-title'> {formatMessage(action.labelId)}</div>
            {action.items.map((item, idType) => (
                <Nav.Link href="#"
                key={idType}
                active={contentType === `${action.type}-${item.type}`}
                onClick={() => navigateCallback(action, item)}
                className='add-nav-item action-nav'
            >
                {formatMessage(item.labelId)}
            </Nav.Link>
            ))}
        </div>))}
        <AW937HideElementsForCondition>
            <div className='d-flex flex-column'>
                <div className='add-nav-title'> {formatMessage({id: 'MARKETPLACE.BALANCES'})}</div>
                {myMaterials
                    ? <ResourceList materials={myMaterials} />
                    : <Skeleton width={'3rem'} className="ms-auto "/>
                }
            </div>
        </AW937HideElementsForCondition>
    </>);
};

const MarketplaceNavComponent = connect(
    (state) => ({
        isProd: state.common.isProd,
        contentType: state.marketplace.contentType,
        selectedItem: state.marketplace.selectedItem,
        member: state.member,
        myMinerals: state.member.minerals,
        myWood: state.member.wood,
        isAllowedVB: state.member.isAllowedVB,
    }),
    (dispatch) => ({
        setContentType: (contentType) => {
            dispatch(setMarketplaceContentType(contentType));
        },
        setSelectedItem: (item) => {
            dispatch(setMarketplaceSelectedItem(item));
        },
    }),
)(injectIntl(MarketplaceNav));

export default MarketplaceNavComponent;
