import MineralsDict from "../../routes/goldrush/minerals";
import cn from 'classnames';

const ResourceList = ({materials=[], currency, abey, abeyLess=false, mobile=false}) => <div className={"resource-list"  + (mobile ? ' flex-flow-row flex-wrap'  : '')}>
    {currency && <div className={cn("resource-list-item", {'text-red': abeyLess})}>
        {
            (currency.type === "ABEY") && <i className="faw-abey me-1 gradient"></i>
        }
        {
            (currency.type === "TICKET" || currency.type === "VTICKET") && <i className="faw-stickers"></i>
        } x{currency.val}
    </div>}
    {abey && <div className={cn("resource-list-item", {'text-red': abeyLess})}>
        <i className="faw-abey me-1 gradient"></i> x{abey}
    </div>}
    {materials.map(item => <div key={item.materialId} className={"resource-list-item" + (item.isLess ? " text-red": "")}>
        <img className="resource-list-icon me-1" src={MineralsDict[item.materialId]?.icon} alt={MineralsDict[item.materialId]?.name} />x{item?.balance || item?.amount || 0}
    </div>)}

</div>

export default ResourceList;
