import {Dropdown} from "react-bootstrap";
import {useParams} from "react-router-dom";

import { menuLang } from '../services/language';


const LanguageMenu = ({variant}) => {
    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}`, '');

    return (<div className="d-flex">
        <Dropdown className="d-flex" align="start">
            <Dropdown.Toggle variant={variant ?? ''} id="dropdown-basic">
                {menuLang.filter((item) => item.href === `/${lang}` )[0].label}
            </Dropdown.Toggle>
            <Dropdown.Menu align={"start"} className="langs">
                { menuLang.map(({href, label}, index) => {
                    return <Dropdown.Item key={index} href={`${href}${postfix}`} className={lang === href.replace('/', '') ? 'active':''}>{label}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    </div>);
};

export default LanguageMenu;
