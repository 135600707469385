import {useEffect, useState} from "react";
import { Form, OverlayTrigger} from "react-bootstrap";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {setShowModalAbeyWallet, setWalletAddress, setWalletAddressStatus, setShowModalLinkedWalet} from "../../../actions";
import Skeleton from "react-loading-skeleton";
import walletAddress from "../../../api/walletAddress";
import Modal from "../../../components/refactoring/ModalPage";
import Alert from "../../../components/refactoring/Alert";

import cn from "classnames";

const ModalAbeyWallet = ({intl, member, setShowModalAbeyWallet, mobile, setWalletAddress, setWalletAddressStatus, setShowModalLinkedWalet}) => {
    const { formatMessage } = intl;

    const [isSendRequest, setIsSendRequest] = useState(false);

    const [checked, setChecked] = useState(false);
    const onCheckboxClick = () => setChecked(!checked);

    const [copied, setCopied] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const idTooltip = setTimeout(() => {
            setShowTooltip(false);
        }, 2000);
        const idCopied = setTimeout(() => {
            setCopied(false);
        }, 2500);
        return () => {
            clearTimeout(idTooltip);
            clearTimeout(idCopied);
        }
    }, [copied])

    let address = ``;
    if (member.address) {
        address = `${member.address}`;
    }

    const icon = member.address
        ? <div className={"icon text-green"}><i className="d-block faw-check_round" /></div>
        :<div className={"icon text-red"}><i className="d-block faw-cross_round" /></div>;

    const onCloseClick = () => setShowModalAbeyWallet(false);
    const onCopyOver = () => {
        if(!showTooltip) {
            setShowTooltip(true);
        }
    };
    const onCopyOut = () => {
        if(showTooltip && !copied) {
            setShowTooltip(false);
        }
    };
    const onCopyClick = () => {
        setCopied(true);
        setShowTooltip(true);
        window.navigator.clipboard.writeText(member.address);
    };
    const onYesClick = () => {
        setShowModalLinkedWalet(true);
        setShowModalAbeyWallet(false);
    };
    const loadData = () => {
        return Promise.all([
            walletAddress.address().then(response => response.json()),
        ]).then(data => {
            const [walletData] = data;
            setWalletAddress(walletData.address);
            setWalletAddressStatus(walletData.status);
        });
    };
    const onOkClick = () => {
        setIsSendRequest(true);
        walletAddress.confirm(address)
            .then(response => response.json())
            .then((data) => {
                loadData();
                setIsSendRequest(false);
            })
            .catch(() => {
                loadData();
                setIsSendRequest(false);
            });
    };

    const onKeyDownHandler = (event) => {
        if (event.key === 'Escape') {
            onCloseClick();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', onKeyDownHandler);
        return () => {
            window.removeEventListener('keydown', onKeyDownHandler);
        };
    }, []);

    return (<>
        <Modal.Container  show={!!true} onClose={onCloseClick} className="ad-net">
            <Modal.Header ><div className="d-flex gap-3">{icon} <div>{formatMessage({id:"PLOT_PROFILE.ABEY_2_WALLET"})}</div></div></Modal.Header>
            <Modal.Body>

                {member.address
                    ? 
                    <div className="marketplace-sell-action w-100 gap-3">
                        {member.addressStatus === 'added' && <div>
                            <div className="form-check d-block mb-3" >
                                <Form.Check.Input checked={checked} onChange={() => {}} onClick={onCheckboxClick}  />
                                <Form.Check.Label className="check-type" onClick={onCheckboxClick} >{formatMessage({id:'WALLET_CONFIRMATION.CONFIRM_CHECKBOX_LABEL_SIMPLE'})}</Form.Check.Label>
                            </div>
                        </div>}

                        <div className="form-block">
                            <div className="label mb-1">
                                {formatMessage({'id':'LINKED_WALLET.WALLET_ADDRESS'})}:
                            </div>
                            <div className="value">
                                <div className="d-flex">
                                    <div className={cn("flex-1 me-2", (mobile ? 'redesign-text-break-all' : 'redesign-text-ellipsis'))} >
                                        {address}
                                    </div>
                                    <div {...{onMouseOver:onCopyOver, onMouseOut:onCopyOut}}>
                                        <OverlayTrigger
                                            placement={'left'}
                                            trigger={['click', 'hover']}
                                            show={showTooltip}
                                            overlay={ ({ arrowProps, placement, ref, style }) => {
                                                return (
                                                    <div className="tooltip me-2" ref={ref} style={style}>
                                                        <span className="tooltip-inner" dangerouslySetInnerHTML={{__html: formatMessage({id:copied ? 'COMMON.COPIED':'COMMON.COPY'})}} />
                                                    </div>
                                                );
                                            }
                                            }>
                                            <div className="">
                                                
                                                <i className="faw-copy cursor-pointer" onClick={onCopyClick}  />
                                            </div>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <Alert type="info" className={"w-100"} text={formatMessage({id:'LINKED_WALLET.YOU_HAVE_TO_LINK'})}></Alert>
                }

                {member.address && <Alert type="info"
                    text= {formatMessage(
                        {
                            id:'LINKED_WALLET.CREATE_SUPPORT_REQUEST',
                        },
                        {
                            tag_open: `<a class="text-link text-regular" target="_blank" href="${process.env.REACT_APP_SUPPORT_LINK}">`,
                            tag_close: `</a>`,
                        }
                    )} 
                />}

                {isSendRequest && <Skeleton count={1} height={'48px'} className="table-wrap"></Skeleton>}

            </Modal.Body>

            <Modal.Footer className={cn({'flex-column-reverse': mobile})}>
                {member.address
                    ? <>
                        <button onClick={onCloseClick} className={"redesign-btn btn-square outline-common"}>{formatMessage({id:"COMMON.CLOSE"})}</button>
                        {member.addressStatus === 'added' && <button className="redesign-btn btn-square filled"  onClick={onOkClick} disabled={!checked} type={"submit"} >{formatMessage({id:"COMMON.CONFIRM"})}</button>}
                    </>
                    : <>
                        <button onClick={onCloseClick} className={"redesign-btn btn-square outline-common"}>{formatMessage({id:"COMMON.CLOSE"})}</button>
                        <button className="redesign-btn btn-square filled"  onClick={onYesClick} type={"submit"} >{formatMessage({id:"COMMON.YES"})}</button>
                    </>
                }
            </Modal.Footer>
        </Modal.Container>
    </>);
};

export default connect(
    (state) => ({
        member: state.member,
        mobile: state.common.mobile,
    }),
    (dispatch) => ({
        setShowModalAbeyWallet: (showModalAbeyWallet) => {
            dispatch(setShowModalAbeyWallet(showModalAbeyWallet));
        },
        setWalletAddress: (addressStatus) => {
            dispatch(setWalletAddress(addressStatus));
        },
        setWalletAddressStatus: (addressStatus) => {
            dispatch(setWalletAddressStatus(addressStatus));
        },
        setShowModalLinkedWalet: (addressStatus) => {
            dispatch(setShowModalLinkedWalet(addressStatus));
        },
    }),
)(injectIntl(ModalAbeyWallet));
