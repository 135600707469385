import {injectIntl} from "react-intl";
import {Button, Form} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import React from "react";

const Congratulations = ({intl, memberId, confirmType}) => {
    const { lang } = useParams();
    const navigate = useNavigate();

    const {formatMessage} = intl;

    const handleSubmit = () => {
        navigate(`/${lang}/login`);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className={"w-100 mb-3 text-center"}>
                <i style={{'fontSize': '3rem'}} className={"d-block faw-check_round text-green mb-3"}></i>
            </div>
            <h5 className={"mb-2"}>{formatMessage({'id':"COMMON.CONGRATULATIONS"})}!</h5>
            <div className="form-note mb-3 text-center mb-4" >
                {formatMessage({'id':"REGISTRATION.YOU_HAVE_BEEN_REGISTRED"})}
            </div>
            <div className={"congratulation__note p-3 mb-2"}>
                <p className={"text-muted mb-2"}>{formatMessage({'id':"REGISTRATION.YOUR_MEMBER_ID"})}</p>
                <p className={"form-note mb-0"}>{memberId}</p>
            </div>
            <div className={"congratulation__note p-3 mb-4"}>
                <p className={"text-muted mb-2"}>{formatMessage({'id':"REGISTRATION.YOUR_PASSWORD"})}:</p>
                <p className={"form-note mb-0"}>{formatMessage({'id': confirmType === "phone" ? "REGISTRATION.PASSWORD_SEND_SMS" : "REGISTRATION.PASSWORD_SEND_EMAIL"})}</p>
            </div>
            <Button variant="info" className={"w-100"} type="submit">
                {formatMessage({'id':"COMMON.DONE"})}
            </Button>
        </Form>
    );
};

export default connect(
    (state) => ({
        memberId:state.registration.memberId,
    }),
    (dispatch) => ({})
)(injectIntl(Congratulations));
