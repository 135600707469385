import {
    SET_APP_TITLE
} from '../../actions/staff';

const initialState = {
    appTitle: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_APP_TITLE:
            return {...state, appTitle: action.payload}
        default:
            return state;
    }
};
