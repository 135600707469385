import Header from "../root/main/Header";
import Middleware from "../../services/Middleware";
import { useEffect } from 'react';
import {Helmet} from "react-helmet-async";
import {injectIntl} from "react-intl";
import {useParams, Link, useLocation, useNavigate} from "react-router-dom";
import Mobile from "../../services/Mobile";
import {connect} from "react-redux";

const AdsNotification = ({
  intl,
  mobile
}) => {
  const { formatMessage } = intl;
  const { lang } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(!location.state) navigate(`/${lang}/ads-manager`)
  }, [location, navigate, lang])

  return (<>
    <Helmet>
        <title>{`${formatMessage({ id: 'HEADER.VLANDS' })} ${formatMessage({ id: 'HEADER.ADS' })}`}</title>
        <meta name="description" content="" />
    </Helmet>
    <div className={"App main-container "  + (mobile ? ' my-plots' : 'anno')}>
        <div className={"inner__container " + (mobile ? 'p-0' : 'pt-4')}>
          <div className={"w-100 d-flex justify-content-center px-3"} >
            <div className="mt-5 market-buildings--congratulations">
              <i style={{fontSize:'2.75rem'}} className={"d-block " + (location?.state?.failed ? 'faw-cross_round text-red':'faw-check_round text-green')}></i>
              <div className="market-buildings--congratulations-title">{
                 (location?.state?.title) ? (location?.state?.title) : ((location?.state?.failed) ?
                  <>{formatMessage({id:'MARKETPLACE.ERROR'})}!</> : <>{formatMessage({id:'MARKETPLACE.CONGRATULATIONS'})}!</>
                 )
              }</div>
              {location?.state?.message && <div className="market-buildings--congratulations-message" style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: location.state.message}}></div>}
              <div className="market-buildings--congratulations-devider"></div>
              <div className="market-buildings--congratulations-action">
                <Link className="market-buildings--congratulations-goto" to={location?.state?.after || `/${lang}/ads-manager`}>
                      {formatMessage({'id':'COMMON.OK'})}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Header />
    </div>
  </>);
}

const AdsNotificationComponent = injectIntl(AdsNotification);

const AdsNotificationComponentWrapped =  connect(
  (state) => ({
      mobile: state.common.mobile,
  }),
  () => ({}),
)(injectIntl(AdsNotificationComponent));

export default () => (
  <Middleware>
    <Mobile />
    <AdsNotificationComponentWrapped />
  </Middleware>
);
