import {
    SET_STAFF_MEMBER,
    SET_STAFF_CLOSEST_EXPIRATION,
} from "../../actions/staff";
const initialState = localStorage.getItem('staffMember')
    ? JSON.parse(localStorage.getItem('staffMember'))
    : {
        id: 0,
        email:'',
        firstName:'',
        lastName:'',
        updatedAt:'',
        closestExpiration:'',
    }
;

export default (state = initialState, action) => {
    switch (action.type) {
        case  SET_STAFF_MEMBER:
            return {...action.payload};
        case  SET_STAFF_CLOSEST_EXPIRATION:
            return {...state, closestExpiration:action.payload};
        default:
            return state;
    }
};
