import iron from '../../assets/iron.svg';
// import copper from '../../assets/copper.svg';
import diamond from '../../assets/diamond.svg';
import gold from '../../assets/gold.svg';
import platinum from '../../assets/platinum.svg';
import silver from '../../assets/silver.svg';
import woodHard from '../../assets/wood_hard_market.png';
import woodSoft from '../../assets/wood_soft_market.png';
import woodLuxury from '../../assets/wood_luxury_market.png';

export default {
    1: {
        icon:iron, name: 'GOLDRUSH.IRON',
    },
    2: {
        icon:silver, name: 'GOLDRUSH.SILVER',
    },
    3: {
        icon:gold, name: 'GOLDRUSH.GOLD',
    },
    4: {
        icon:platinum, name: 'GOLDRUSH.PLATINUM',
    },
    5: {
        icon:diamond, name: 'GOLDRUSH.DIAMOND',
    },
    6: {
        icon: woodSoft, name: 'LUMBER_YARD.SOFT_WOOD'
    },
    7: {
        icon: woodHard, name: 'LUMBER_YARD.HARD_WOOD'
    },
    8: {
        icon: woodLuxury, name: 'LUMBER_YARD.LUXURY_WOOD'
    }
};
