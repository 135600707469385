import {Dropdown, Nav} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { useLayoutEffect, useRef, useState } from "react";

import {setGoldrushMainstate, setLyState, setMarketplaceDefault} from "../../../actions";

import MenuItemWithSubmenu from "./MenuItemWithSubmenu";
import AW937HideElementsForCondition from "../../../components/crutches/AW937HideElementsForCondition";
// import { hide } from "@popperjs/core";
import { BASE_DOMAIN } from "etc/define";

const HideDropdownIfEmpty = ({children, toggler}) => { // TODO: Refactor this component after show AW937HideElementsForCondition
    const container = useRef(); //! TEMP REF: temp ref to check if children exists (component AW937HideElementsForCondition always return themselfs, so we can't check children is empty or not)
    const [display, setDisplay] = useState(true);
    useLayoutEffect(() => {
        setDisplay((container.current?.childElementCount > 0 ?? true) ? true: false);
    }, [container, children])
    return (
        display && <Dropdown>
            {toggler}
            <Dropdown.Menu ref={container} renderOnMount>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}

const HeaderShortMenu = ({intl, member, yesNetCallback, memberHasPlots, _2DAccess}) => {
    const {formatMessage} = intl;
    let navigate = useNavigate();
    const { lang } = useParams();
    const postfix = window.location.pathname.replace(`/${lang}/`, '');

    return (<>
        <AW937HideElementsForCondition hide={!memberHasPlots}>
            <Nav.Link  active={[`marketplace`].indexOf(postfix) !== -1} className={"px-3 py-2"} href={`/${lang}/marketplace`} onClick={() => {navigate(`/${lang}/marketplace`); setMarketplaceDefault()}}>{formatMessage({id:'LINK.MARKETPLACE'})}</Nav.Link>
        </AW937HideElementsForCondition>

        <HideDropdownIfEmpty toggler={
            <Dropdown.Toggle variant={"header"} className={['announcements', 'game-center', 'adnet-catalog', 'ads-overview', 'ads-manager', 'ads-schedule', 'ads-credit-history', `gold-rush`, `wow`, 'lumber-yard'].indexOf(postfix) !== -1 ? "active":''}>
                {formatMessage({id:'HEADER.MORE'})}
            </Dropdown.Toggle>
            }>
            <AW937HideElementsForCondition>
                <MenuItemWithSubmenu
                    {...{
                        pages: ['game-center'],
                        name:formatMessage({id:'HEADER.GAMES'}),
                        code:'game',
                        items: [{
                            'onClick':() => { setGoldrushMainstate('default') },
                            'href':`/${lang}/game-center`,
                            'className':[[`game-center`].indexOf(postfix) !== -1 ? "active":'',],
                            'name':formatMessage({id:'MARKETPLACE.GAME_CENTER'}),
                        }, {
                            'href': `https://xracer.game/`,
                            target: "_blank",
                            'className':[],
                            'name':'XRacer',
                        }, {
                            'href': `/${lang}/gold-rush`,
                            'className':[[`gold-rush`].indexOf(postfix) !== -1 ? "active":''],
                            'name':formatMessage({id:'HEADER.GOLD_RUSH'}),
                        }, {
                            'href': `/${lang}/lumber-yard`,
                            'className':[[`lumber-yard`].indexOf(postfix) !== -1 ? "active":''],
                            'name':formatMessage({id:'HEADER.LUMBER_YARD'}),
                        }
                        ]
                    }}
                />
            </AW937HideElementsForCondition>
            {_2DAccess && <Dropdown.Item
                href={`https://2d.${BASE_DOMAIN}/`} target="_blank" rel="noreferrer">{formatMessage({id:'HEADER.2D'})} </Dropdown.Item>}

            {member.adnetIsVisible && <Dropdown.Item
                onClick={(e) => { e.preventDefault(); navigate(`/${lang}/ads-overview`); setGoldrushMainstate('default'); }}
                className={postfix.indexOf(`ads-`) !== -1 ? "active":''}
            >
                {formatMessage({id:'HEADER.ADS'})}
            </Dropdown.Item>}
            <Dropdown.Item
                onClick={(e) => { e.preventDefault(); navigate(`/${lang}/downloads`) }}
                className={postfix.indexOf(`downloads`) !== -1 ? "active":''}
                href={`/${lang}/downloads`} >{formatMessage({id:'LINK.DOWNLOADS'})} </Dropdown.Item>
        </HideDropdownIfEmpty>

        <Nav.Link  active={!!(postfix.match(/^support\/(all|open|in-progress|closed)/) || [`announcements`, 'events', 'tutorials'].indexOf(postfix) !== -1)} className="d-flex align-items-center" onClick={() => {navigate(`/${lang}/announcements`)}}>
            {formatMessage({id:'HEADER.SUPPORT'})}
            {!![member.unreadEvents, member.unreadAnnouncements].filter(item => item).length && <div className={"unread--marker ms-1"}></div>}
        </Nav.Link>
    </>);
};

export default connect(
    (state) => ({
        member: state.member,
        isProd: state.common.isProd,
        memberHasPlots: state.member.memberHasPlots,
        _2DAccess: state.member._2DAccess,
    }),
    (dispatch) => ({
        setGoldrushMainstate: (step) => {
            dispatch(setGoldrushMainstate(step));
        },
        setLumberYardMainstate: (step) => {
            dispatch(setLyState(step));
        },
        setMarketplaceDefault: (state) => {
            dispatch(setMarketplaceDefault(state));
        },
    }),
)(injectIntl(HeaderShortMenu));

