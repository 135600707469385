import {injectIntl} from "react-intl";
import Middleware from "../../services/Middleware";
import { Form, Button } from 'react-bootstrap';
import FormInput from '../../components/FormInput';
import loginService from '../../api/login';
import {setMemberData, setAccessToken, setExpiredAt} from "../../actions";
import api from "../../api/core/api";
import { useParams, useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import { Spinner } from "react-bootstrap";
import {connect} from "react-redux";
import {getExpiredAt} from "../../services/utils";
import {Helmet} from "react-helmet-async";

import TopBanner from '../root/landingInterWorld/Home/TopBanner';
import cn from "classnames";
import styles from "../root/landingInterWorld/assets/styles/styles.module.scss";
import Footer from "../root/landingInterWorld/Home/Footer";


const Login = ({intl, setExpiredAt, setAccessToken, mobile}) => {
    let navigate = useNavigate();
    const [loginProcess, setLoginProcess] = useState(false);
    const [errors, setErrors] = useState({});
    const { lang } = useParams();

    useEffect(() => {
        if (mobile) {
            document.querySelector("body")
                .setAttribute('class', 'mobile overflow-auto');
        }
    }, [mobile]);

    const {formatMessage} = intl;

    const handleSubmit = (event) => {
        setLoginProcess(true);
        setErrors({});
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const values = Object.fromEntries(data.entries());

        if (values.member_id.length && values.password.length) {
            loginService.login(values.member_id, values.password)
                .then(response => response.json())
                .then((data) => {
                    api.setAccessToken(data.accessToken);
                    setAccessToken(data.accessToken);
                    setExpiredAt(getExpiredAt(data.expiresIn));
                    return loginService.member();
                })
                .then(response => response.json())
                .then(data => {
                    setMemberData(data);
                    let postfix = localStorage.getItem('afterLogin') ?? '';
                    localStorage.removeItem('afterLogin');
                    window.location = `/${lang}${postfix}`;
                })
                .catch((data) => {
                    setLoginProcess(false);
                    data.response.json().then(data => {
                        if (data.errors) {
                            setErrors(data.errors);
                        }
                    });
                });
        } else {
            setLoginProcess(false);
            setErrors({
                'username': !values.member_id.length,
                'password': !values.password.length,
            });
        }
    };

    return (<>
        <Helmet>
            <title>{formatMessage({ id: 'SEO.LOGIN_TITLE' })}</title>
            <meta name="description" content={formatMessage({ id: 'SEO.LOGIN_DESC'})} />
        </Helmet>
        <div className={cn(styles.wrapper, 'w-100')}>
                <TopBanner isShort={true}/>

        
           
                <div className="d-flex align-items-center login__wrap">
                    <Form onSubmit={handleSubmit}>
                        <h5>{formatMessage({'id':"LOGIN.WELCOME"})}</h5>
                        <FormInput
                            {...{
                                className: '',
                                controlId: 'formBasicMemberId',
                                label: formatMessage({'id':"COMMON.MEMBER_ID"}),
                                controlType: 'number',
                                controlName: 'member_id',
                                placeholder: formatMessage({'id':"COMMON.MEMBER_ID"}),
                                error: errors.username,
                                errorParams: {seconds:errors.seconds},
                            }}
                        />

                        <FormInput
                            {...{
                                groupClassName: 'mb-2',
                                groupStyle: {'marginTop':mobile ? '16px':'12px'},
                                controlId: 'formBasicPassword',
                                label: formatMessage({'id':"COMMON.PASSWORD"}),
                                controlType: 'password',
                                controlName: 'password',
                                placeholder: formatMessage({'id':"COMMON.PASSWORD"}),
                                error: errors.password,
                                errorParams: {},
                            }}
                        />

                        {
                            loginProcess
                                ? <div className="text-center mt-4">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                                : <Button variant="warning" className={"w-100 mt-4"} type="submit">
                                    {formatMessage({'id':"LOGIN.LOG_IN"})}
                                </Button>
                        }

                        <div className="form-text mt-4">
                            {formatMessage({'id':"LOGIN.DONT_HAVE_ACCOUNT"})} <a href={"#"} onClick={() => navigate(`/${lang}/registration`)}>{formatMessage({'id':"LOGIN.SIGN_UP"})}</a>
                        </div>
                    </Form>
                </div>

                <Footer />
            
        </div>
    </>);
};

const LoginComponent = connect(
    (state) => ({
        mobile: state.common.mobile,
    }),
    (dispatch) => ({
        setAccessToken: (accessToken) => {
            dispatch(setAccessToken(accessToken));
        },
        setExpiredAt: (expiresAt) => {
            dispatch(setExpiredAt(expiresAt));
        },
    })
)(injectIntl(Login));

export default (() => (
    <Middleware>
        <LoginComponent />
    </Middleware>
));
