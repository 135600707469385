import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Alert, Card, Form} from "react-bootstrap";
import BalanceItem from "./BalanceItem";

const MobileModalAWBalances = ({intl, mobile, show, onClose, setShowSysWalletToAbey, itwIsAccese}) => {
    const { formatMessage } = intl;

    const onButtonClick = () => {
        onClose();
        setShowSysWalletToAbey(true);
    };
    return !mobile || !show
        ? null
        : <div className="confirmation-container modal-abey-wallet modal-aw-balances">
            <div className="confirmation-close-overlay" onClick={() => onClose()}></div>
            <Card className={"text-center"}>
                <Card.Title className={"text-center"}>{formatMessage({"id":"AW458.ABEY_BALANCE"})}</Card.Title>

                <Alert  variant={"light"} className={"text-start aw-mobile-balances orange"}>
                    <Form.Label>
                        {formatMessage({"id":"COMMON.AW_BALANCE"})}:
                    </Form.Label>
                    <div className="d-flex align-items-center">
                        <i className={`faw-abey d-block me-1`}></i>
                        <BalanceItem type="balanceOrdinary" icon="faw-abey " />
                    </div>
                </Alert>
                <Alert  variant={"light"} className={"text-start aw-mobile-balances purple"}>
                    <Form.Label>
                        {formatMessage({"id":"COMMON.AW_BONUS_BALANCE"})}:
                    </Form.Label>
                    <div className="d-flex align-items-center">
                        <i className={`faw-abey d-block me-1`}></i>
                        <BalanceItem type="balanceBonus" icon="faw-abey " />
                    </div>
                </Alert>
                {itwIsAccese && <Alert  variant={"light"} className={"text-start aw-mobile-balances apple mb-0"}>
                    <Form.Label>
                        {formatMessage({"id":"COMMON.ITW_BALANCE"})}:
                    </Form.Label>
                    <div className="d-flex align-items-center">
                        <i className={`faw-abey d-block me-1`}></i>
                        <BalanceItem type="balanceItw" icon="faw-abey " />
                    </div>
                </Alert>}
                <hr/>
                <button className="redesign-btn btn-square filled" onClick={onButtonClick}>{formatMessage({id:'AW458.TOP_UP_ABEY_BALANCE'})}</button>
            </Card>
        </div>
    ;
};

const MobileModalAWBalancesComponent = connect(
    state => ({
        mobile: state.common.mobile,
        itwIsAccese: state.member.accessItw,
    }),
    dispatch => ({}),
)(injectIntl(MobileModalAWBalances));


export default MobileModalAWBalancesComponent;
