import {useState} from 'react';
import { injectIntl} from "react-intl";
import { Helmet} from "react-helmet-async";
import { connect} from "react-redux";
import Modal from "../../../components/refactoring/ModalPage";
import Alert from "../../../components/refactoring/Alert";
import FormInput from "../../../components/refactoring/FormInput";
import walletAddressService from '../../../api/walletAddress';
import {
    setWalletAddress, setWalletAddressStatus, changeCrossSuccessList, setShowModalLinkedWalet
} from '../../../actions';

import cn from "classnames";

const ModalLinkedWallet = ({intl, mobile, member, setWalletAddress, setWalletAddressStatus, setShowModalLinkedWalet, changeCrossSuccessList}) => {
    const { formatMessage } = intl;
    const [isLoading, setIsLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [errors, setErrors] = useState({});

    const onCloseClick = () => {
        setShowModalLinkedWalet(false);
    }

    const onAddAdress = () => {
        setIsLoading(true);
        walletAddressService.bind(address)
            .then(resp => resp.json())
            .then(() => {
                walletAddressService.address().then(resp => resp.json())
                    .then((walletData) => {
                        setWalletAddressStatus(walletData.status);
                        setWalletAddress(address);
                        onCloseClick();
                        changeCrossSuccessList([{failed: false, text: formatMessage({id:'LINKED_WALLET.SUCCESS_TEXT_LINKED'})}]);
                    });
            })
            .catch(err => {
                err.response.json().then(data => {
                    setErrors(data.errors);
                });
            })
            .finally(() => setIsLoading(false));
    };

    return (<>
        <Helmet>
            <title>{`${formatMessage({ id: 'HEADER.VLANDS' })} ${formatMessage({ id: 'HEADER.LINKED_WALLET' })}`}</title>
            <meta name="description" content="" />
        </Helmet>

        <Modal.Container  show={!!true}  onClose={onCloseClick}>
            <Modal.Header >{formatMessage({id:"HEADER.LINKED_WALLET"})}</Modal.Header>
            <Modal.Body>
                <div style={{fontSize: "18px", fontWeight: "700"}}>
                    {formatMessage({id:'PLOT_PROFILE.ADD_ABEY2_WALLET_MOBILE'})}
                </div>

                <FormInput
                    groupClassName='w-100'
                    defaultValue={address}
                    label={formatMessage({'id':"COMMON.WALLET_ADDRESS"})}
                    controlType='text'
                    error={errors.address}
                    errorParams={{attribute:formatMessage({'id':"COMMON.NAME"}).toLowerCase()}}
                    onControlChange={e => setAddress(e.target.value)}
                />

                <Alert type='info' text={formatMessage({id:'LINKED_WALLET.NOTE'})}/>

            </Modal.Body>

            <Modal.Footer className={cn({'flex-column-reverse': mobile})}>
                <button onClick={onCloseClick}  disabled={isLoading} className={"redesign-btn btn-square outline-common"}>{formatMessage({id:"COMMON.CLOSE"})}</button>
                <button className="redesign-btn btn-square filled"   disabled={isLoading || !address} onClick={onAddAdress} type={"submit"} >{formatMessage({id:"LINKED_WALLET.ADD_WALLET_ADDRESS"})}</button>
            </Modal.Footer>
        </Modal.Container>

    </>);
};

const ModalLinkedWalletComponent = connect(
    (state) => ({
        mobile: state.common.mobile,
        member: state.member,
    }),
    (dispatch) => ({
        setShowModalLinkedWalet: (addressStatus) => {
            dispatch(setShowModalLinkedWalet(addressStatus));
        },
        setWalletAddress: (address) => {
            dispatch(setWalletAddress(address));
        },
        setWalletAddressStatus: (status) => {
            dispatch(setWalletAddressStatus(status));
        },
        changeCrossSuccessList: (info) => {
            dispatch(changeCrossSuccessList(info));
        }
    }),
)(injectIntl(ModalLinkedWallet));


export default ModalLinkedWalletComponent;

