import {injectIntl} from "react-intl";
import {useState, useRef, useEffect, useMemo} from "react";
import _uniqueId from 'lodash/uniqueId';

import cn from "classnames";

const STATES = {
    idle: 'idle',
    focused: 'focused',
    hovered: 'hovered',
}

// const CHANGE_EVENTS_LIST = ['input', 'copy', 'paste', 'cut', 'drop'];

export const FormInput = ({
  intl,
  label: title,
  subLabel,
  subLabelhtml,
  refInput,
  controlId,
  groupClassName,
  inputClassName="",
  labelClassName="",
  groupStyle,
  controlStyle,
  controlName,
  controlType = 'text',
  error,
  errorParams,
  min,
  max,
  maxLength,
  rows,
  onControlChange = () => {},
  onControlBlur = () => {},
  onKeyPress = () => {},
  onKeyDown = () => {},
  onInput = () => {},
  disabled = false,
  defaultValue,
  selectOptions,
  size = 'sm',
  icon,
  value,
  isEmptyPermanent,
}) => {
    const id = useMemo(() => _uniqueId('form-input-'), []);
    const { formatMessage } = intl ? intl : {};
    const [passwordText, setPasswordText] = useState(false);
    const handleChangePasswordType = () => setPasswordText(!passwordText);
    const groupClasses = [
        groupClassName ?? '',
        controlType === 'password' ? 'position-relative':''
    ];

    const controlClasses = [
        (inputClassName ?? "") + (error ? ' is-invalid':''),
    ];
    const errorText = error 
        ? (Array.isArray(error)
            ? error.map((item) => {
                if (formatMessage) {
                    return formatMessage({id:item}, errorParams ? (errorParams[item] || [errorParams.attribute]) : "");
                } else {
                    switch (item) {
                        case 'auth.failed':
                            return 'Auth failed';
                        default:
                            return item;
                    }
                }
            }).join('<br />')
            : error)
        : '';

    const EyePassword = () => (<i onClick={handleChangePasswordType} style={{'fontSize':'1.5rem','top':'36px','right':'19px','color':'rgba(75, 86, 119, 0.85)'}} className={"position-absolute cursor-pointer d-block " + (passwordText ? 'faw-eye_open':'faw-eye_close')} alt=""/>);
    
    const [isFocused, setFocus] = useState(false);
    const [isHovered, setHover] = useState(false);
    const [isEmpty, setIsEmpty] = useState(!defaultValue);
    const inputRef = useRef(null);
    const actualRef = refInput || inputRef;
    useEffect(() => {
      if (actualRef.current) {
        const input = actualRef.current;

        const eventHandlerFocus = () => {
            setFocus(true);
        };
        input.addEventListener("focus", eventHandlerFocus);

        const eventHandlerBlur = () => {
            setFocus(false);
        };
        input.addEventListener("blur", eventHandlerBlur);

        return () => {
            input.removeEventListener("focus", eventHandlerFocus);
            input.removeEventListener("blur", eventHandlerBlur);
        }
      }
    }, [actualRef]);


    useEffect(() => {
        if (typeof value === 'string') setIsEmpty(value.length === 0);
    }, [value]);

    const inputState = useMemo(() => {
        if (isFocused) {
            return STATES.focused;
        } else if (isHovered) {
            return STATES.hovered;
        } else {
            return STATES.idle;
        }
    }, [isFocused, isHovered]);

    const inputProps = useMemo(() => {
        const props = {
            value,
            defaultValue,
        }
        if (props.defaultValue === undefined) delete props.defaultValue;
        if (props.value === undefined) delete props.value;

        return props;
    }, [value, defaultValue])
    
    return (
        <label style={groupStyle} className={cn('iw_input__group', size, groupClasses, inputState, {'empty': isEmptyPermanent ?? isEmpty, disabled}, controlType)} controlid={controlId} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {title && <span className={cn('input-title')}>{title}</span>}
            {controlType === 'select' 
                ?<>
                    <select ref={actualRef} id={id} tabIndex={disabled ? -1 : 0}
                    {...inputProps}
                    onChange={onControlChange}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    onBlur={onControlBlur}
                    onInput={onInput}
                    disabled={disabled}
                    min={min}
                    max={max}
                    className={cn('iw_input', controlClasses)}
                    type={passwordText ? 'text':controlType}
                    defaultValue={defaultValue || ''}
                    name={controlName}>
                    {
                        selectOptions.map(({value, title, disabled}, indx) => {
                            return <option key={indx} value={value} disabled={disabled ?? false}>{title}</option>;
                        })
                    }
                    </select>
                    <i className="iw_input__icon faw-arrow_round select"></i>
                </> 
                :controlType === 'textarea' ? <textarea tabIndex={disabled ? -1 : 0}
                    ref={actualRef} id={id}
                    {...inputProps}
                    onChange={(event) => {
                        onControlChange(event);
                        setIsEmpty(event.target.value?.length === 0 ?? false);
                    }}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    onBlur={onControlBlur}
                    onInput={onInput}
                    disabled={disabled}
                    row={min}
                    rows={rows}
                    maxLength={maxLength}
                    className={cn('iw_input', controlClasses)}
                    name={controlName}
                    style={controlStyle}
                ></textarea>
                :controlType === 'textcontainer' ? <div tabIndex={disabled ? -1 : 0}
                    ref={actualRef} id={id}
                    {...inputProps}
                    disabled
                    row={min}
                    maxLength={maxLength}
                    className={cn('iw_input', controlClasses)}
                >{inputProps.defaultValue || inputProps.value}</div>
                :<input tabIndex={disabled ? -1 : 0}
                    ref={actualRef} id={id}
                    {...inputProps}
                    onChange={(event) => {
                        onControlChange(event);
                        setIsEmpty(event.target.value?.length === 0 ?? false);
                    }}
                    onKeyPress={onKeyPress}
                    onKeyDown={onKeyDown}
                    onBlur={onControlBlur}
                    onInput={onInput}
                    disabled={disabled}
                    min={min}
                    max={max}
                    className={cn('iw_input', controlClasses)}
                    type={passwordText ? 'text':controlType}
                    name={controlName}
                    maxLength={maxLength}
                />
            }
            { icon && <div className={cn('iw_input__icon_container')}>{icon}</div>}
            { controlType === 'password' ? <EyePassword />:null }
            { error && (error !== true) && <div className={cn("form-control-error", 'iw_input__bottom_label', {select: controlType === 'select'})} dangerouslySetInnerHTML={{__html: errorText}} /> }
            {(!!subLabel || !!subLabelhtml) && <div className={cn("iw_input__bottom_label", labelClassName)}>
                {!!subLabel && <span>{subLabel}</span>}
                {!!subLabelhtml && <span dangerouslySetInnerHTML={{__html: subLabelhtml}}></span>}
            </div>}
        </label>
    )
};

export default injectIntl(FormInput);
