import {useCallback, useEffect, useState, lazy, Suspense} from "react";
import {injectIntl} from "react-intl";

import api from "../../api/core/api";
import {getExpiredAt, localStorageClearNot} from "../../services/utils";
import loginService from '../../api/login';
import Middleware from "../../services/Middleware";
import {connect} from "react-redux";
import {setAccessToken, setExpiredAt, setMemberData, setOtp} from "../../actions";
import {useParams, useNavigate} from "react-router-dom";
import MiniLoading from "../../components/MiniLoading";

import contacts from "../../etc/lottie-contacts-loading.json";

const Lottie = lazy(() => import("lottie-react"));

const Loader = ({login, setOtp, setAccessToken, setExpiredAt, setMemberData}) => {
    const { otp, memberId } = login;
    const { lang } = useParams();
    const [logged, setLogged] = useState(false);
    const wasLoggedBefore = localStorage.expiredAt && parseInt(localStorage.expiredAt) > (+new Date());
    let navigate = useNavigate();

    const loginProccess = useCallback(() => {
        loginService.loginByOtp(otp, memberId)
            .then(response => response.json())
            .then(data => {
                setOtp('')
                api.setAccessToken(data.accessToken);
                setAccessToken(data.accessToken);
                setExpiredAt(getExpiredAt(data.expiresIn));
                return loginService.member();
            })
            .then(response => response.json())
            .then(data => {
                navigate(`/${lang}`);
                setMemberData(data);
                setLogged(true);
            })
            .catch(() => {
                navigate(`/${lang}`);
            });
    }, [lang, memberId, otp, setAccessToken, setExpiredAt, setMemberData, setOtp]);

    useEffect(() => {
        if (!logged) {
            if (wasLoggedBefore) {
                loginService.logout().then(() => {
                    localStorageClearNot(['afterLogin', 'promtState']);
                    loginProccess();
                });
            } else {
                loginProccess();
            }
        }
    }, [logged, loginProccess]);

    return (
        <>
            {<Suspense fallback={<MiniLoading />}>
                <Lottie animationData={contacts} loop={true} autoplay={true} style={{maxHeight: 'calc(100vh - 164px)'}} />
            </Suspense>}
        </>
    )
};

const LoaderComponent = connect(
    (state) => ({
        login: state.login,
    }),
    (dispatch) => ({
        setOtp: (otp) => {
            dispatch(setOtp(otp));
        },
        setMemberData: (data) => {
            dispatch(setMemberData(data));
        },
        setAccessToken: (accessToken) => {
            dispatch(setAccessToken(accessToken));
        },
        setExpiredAt: (expiresAt) => {
            dispatch(setExpiredAt(expiresAt));
        },
    }),
)(injectIntl(Loader));

export default () => (
    <Middleware>
        <LoaderComponent />
    </Middleware>
);
