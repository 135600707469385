
import { connect } from "react-redux";
import {setMemberData, setOtp, setAccessToken, setExpiredAt} from "../../actions";
import {useState, Suspense, lazy} from "react";

import Middleware from "../../services/Middleware";

import LandingInterWorld from './landingInterWorld/Home';
import '../../styles/pages/_landing.scss';
// import MiniLoading from "../../components/MiniLoading";

const Main = lazy(() => import('./main/Main'));
// const LandingInterWorld = lazy(() => import('./landingInterWorld/Home'));
const defaultFallback = <div />;

const loadWrapper = (component, fallback = defaultFallback) => {
    return <Suspense fallback={fallback}>
      {component}
    </Suspense>
  }

const Root = ({member, goto, buildPlacing}) => {
    const [logged] = useState(member.id);
    // return defaultFallback;
    return (
        logged
            ? loadWrapper(<Main goto={goto} key={'main'} buildPlacing={buildPlacing}/>)
            : loadWrapper(<LandingInterWorld />, <div></div>)
            // : <LandingInterWorld />
    );
};

const RootRedux = connect(
    (state)=>({
        member: state.member,
        memberId: state.login.memberId,
        otp: state.login.otp,
    }),
    (dispatch)=>({
        setOtp: (otp) => {
            dispatch(setOtp(otp));
        },
        setMemberData: (data) => {
            dispatch(setMemberData(data));
        },
        setAccessToken: (accessToken) => {
            dispatch(setAccessToken(accessToken));
        },
        setExpiredAt: (expiresAt) => {
            dispatch(setExpiredAt(expiresAt));
        },
    })
)(Root);

export default ({buildPlacing}) => (
    <Middleware>
        <RootRedux buildPlacing={buildPlacing} />
    </Middleware>
);
