import api from './core/api';
import qs from "qs";

class Buildings {
    constructor(api) {
        this.api = api;
    }
    catalog(searchParams = {} ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/catalog'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    owned(searchParams = {} ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/owned'),
            { searchParams: qs.stringify( searchParams ) , signal}
        );
    }
    availableAreas(buildingId ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/available-areas'),
            { searchParams: qs.stringify( { buildingId } ) , signal}
        );
    }
    template(id ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/template'),
            { searchParams: qs.stringify( { id } ) , signal}
        );
    }
    building(buildingId ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/building'),
            { searchParams: qs.stringify( { buildingId } ) , signal}
        );
    }

    buildingProfile(buildingId ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/nft/profile'),
            { searchParams: qs.stringify( { buildingId } ) , signal}
        );
    }
    mintBuilding(data, options) {
        return this.api.client.post(
            this.api.getUrl('member/building/nft/mint'),
            {...options,  json: data }
        );
    }
    setOwnerId(ownerId, options) {
        return this.api.client.post(
            this.api.getUrl('member/owner-id'),
            {...options,  json: {ownerId} }
        );
    }
    place(params, signal) {
        return this.api.client.post(
            this.api.getUrl('member/building/place'),
            {signal, json: params}
        );
    }
    remove(buildingId, signal) {
        return this.api.client.delete(
            this.api.getUrl('member/building/remove'),
            { searchParams: qs.stringify( { buildingId } ) , signal}
        );
    }

    buildings(signal) {
        return this.api.client.get(
            this.api.getUrl('member/map/buildings'),
            {signal}
        );
    }

    sizeMapBuilding(buildingId, signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/size-map/building'),
            {searchParams: qs.stringify( { buildingId } ) , signal}
        );
    }

    sizeMapType(type = 'exchange', signal) {
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/size-map/type'),
            {searchParams: qs.stringify( { type } ) , signal}
        );
    }
    
    exchange(
        buildingId,
        templatesId=[],

        options = {}
    ) {
        return this.api.client.post(
            this.api.getUrl('member/building/exchange'),
            {json: {buildingId, templatesId}, options}
        );
    }

    exchangeCatalog(
        buildingId,
        page = 1,
        perPage = 10,
        type = 'exchange',
        sort = 'id',
        direction = 'desc',
        id = null,
        size = null,
        code = null,
        available = null,
        options = {}
    ) {
        const filter = new Map();
        if(id) {
            filter.set('id', id);
        }
        if(size) {
            filter.set('size', size);
        }
        if(code) {
            filter.set('code', code);
        }
        if(available) {
            filter.set('available', available);
        }
        const searchParams = {buildingId, page, perPage,  type, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/catalog'),
            { searchParams: qs.stringify( searchParams ), options }
        );
    }

    exchangeHistory(
        page = 1,
        perPage = 10,
        sort = 'id',
        direction = 'desc',
        id = null,
        size = null,
        code = null,
        available = null,
        options = {},
        exceptCurrency = null
    ) {
        const filter = new Map();

        if(size) {
            filter.set('sourceSize', size);
        }
        if(code) {
            filter.set('code', code);
        }
        if(available) {
            filter.set('available', available);
        }
        if(exceptCurrency) {
            filter.set('exceptCurrency', exceptCurrency);
        }
        const searchParams = { page, perPage, sort, direction, filter:Object.fromEntries(filter.entries())};
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/history'),
            { searchParams: qs.stringify( searchParams ), options }
        );
    }
    exchangePaymentOptions(
        buildingId,
        templateId,
        options = {}
    ) {
        const searchParams = {buildingId, templateId};
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/payment-options'),
            { searchParams: qs.stringify( searchParams ), options }
        );
    }
    
    checkExchange(
        buildingId,
        templatesId = [],
        paymentOption = 'abeyBalanceFirst',
        options = {}
    ) {
        const searchParams = {buildingId, templatesId, paymentOption};
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/estimate'),
            { searchParams: qs.stringify( searchParams ), options }
        );
    }

    preparedPrice(
        buildingId,
        type,
        options = {}
    ) {
        const searchParams = {buildingId, type};
        return this.api.client.get(
            this.api.getUrl('member/building/exchange/price'),
            { searchParams: qs.stringify( searchParams ), options }
        );
    }

    buldingPurchaseHistory(params = {
        page: 1,
        perPage: 10,
        sort: 'id',
        direction: 'desc',
    } ,signal) {
        return this.api.client.get(
            this.api.getUrl('member/purchase/list'),
            { searchParams: qs.stringify( params ) , signal}
        );
    }
    
    buldingPurchaseProductList(signal) {
        return this.api.client.get(
            this.api.getUrl('member/purchase/products?filter[group]=buildings'),
            {signal}
        );
    }
    
}

export default (new Buildings(api));
