import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { createContext, useContext } from 'react';
import Portal from "../Portal";

import cn from "classnames";

export const CloseContext = createContext(false);

const ModalPage = ({
    mobile, 
    show = false, 
    wrapperId="modal-page", 
    closeOverlay=false,
    onClose, 
    children,
    styles = {},
    scrollBody = false,
    className = ""
}) => {
    
    return (<>
        {show && <Portal wrapperId={wrapperId} className={cn("redesign-modal-page", {mobile} )}>
            <div className="overlay-container">
                <div className="overlay-close-overlay" onClick={closeOverlay ? onClose : null}></div>
                <div className={cn("redesign-modal-conteiner", {'scroll-body': scrollBody}, className)} style={styles}>
                    <CloseContext.Provider
                        value={onClose}
                    >
                        {children}
                    </CloseContext.Provider>
                </div>
               
            </div>
        </Portal>}
    </>);
};

const Header = ({
    text,
    children,
    otherHeader
}) => {
    const onClose = useContext(CloseContext);
    return (<>
        {otherHeader 
            ? <>{otherHeader}</>
            : <div className="redesign-modal-header">
            <div className="fliex-1 redesign-text-ellipsis">{children || text}</div>
            <button disabled={!onClose}
                className="redesign-btn modal-close ms-3"
                onClick={!!onClose ? onClose : null}
            >
                <i className="faw-cross"/>
            </button>
        </div>}
    </>);
};

const Body = ({
    text,
    children,
    styles = {},
    className = "",
    ref
}) => {
    return (<div className={cn("redesign-modal-body", className)} style={styles} ref={ref}>
        {children || text}
    </div>);
};

const Footer = ({
    text,
    children,
    className = ""
}) => {
    return (<div className={cn("redesign-modal-footer", className)}>
        {children || text}
    </div>);
};

const Container = connect(
    (state) => ({
        mobile: state.common.mobile,
    }),
    (dispatch) => ({}),
)(injectIntl(ModalPage));

export default {
    Container,
    Header,
    Body,
    Footer
}
