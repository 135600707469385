import {Navigate} from "react-router-dom";

const Empty = () => {

    return <Navigate to={`/staff/announcements`} />
};

const EmptyComponent = () => (
    <Empty />
);

export default EmptyComponent;
