/* eslint-disable no-unused-vars */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import cn from "classnames";

import styles from "../assets/styles/second.module.scss";

export const Second = ({intl, ...props }) => {
  const { formatMessage } = intl;
  
  return (
    <div className={styles.wrapper}>
        <div className={cn(styles.section, styles.left)}>
            <div className={styles.inner}>
              <div className={styles.title}>
                  {formatMessage({id: "landing.vlands.second.section1.title"})}
              </div>
              <div className={styles.text}>
                  {formatMessage({id: "landing.vlands.second.section1.text"})}
              </div>
            </div>
        </div>
        <div className={cn(styles.section, styles.right)}>
            <div className={styles.inner}>
              <div className={styles.title}>
                  {formatMessage({id: "landing.vlands.second.section2.title"})}
              </div>
              <div className={styles.text}>
                  {formatMessage({id: "landing.vlands.second.section2.text"})}
              </div>
            </div>
        </div>
    </div>
  );
};

export default connect(
  (state)=>({
      mobile: state.common.mobile,
  }),
  ()=>({

  })
)(injectIntl(Second));
