import {
    LOGIN_STAFF_PROCESS, SET_STAFF_MEMBER_ID,
    SET_STAFF_OTP,
    SET_STAFF_ACCESS_TOKEN, SET_STAFF_EXPIRED_AT
} from '../../actions/staff';

const initialState = {
    staffLoginProcess: false,
    staffAccessToken: '',
    staffExpiredAt: new Date(),
    staffOtp:'',
    staffMemberId:'',
};

if (localStorage.getItem('staffAccessToken')) {
    initialState.staffAccessToken = localStorage.getItem('staffAccessToken');
}

if (localStorage.getItem('staffExpiredAt')) {
    initialState.staffExpiredAt = new Date(
        parseInt(
            localStorage.getItem('staffExpiredAt')
        )
    );
}

const reduce = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_STAFF_PROCESS:
            return {...state, staffLoginProcess:action.payload};
        case  SET_STAFF_OTP:
            return {...state, staffOtp:action.payload};
        case SET_STAFF_MEMBER_ID:
            return {...state, staffMemberId: action.payload};
        case SET_STAFF_ACCESS_TOKEN:
            return {...state, staffAccessToken:action.payload};
        case SET_STAFF_EXPIRED_AT:
            return {...state, staffExpiredAt: action.payload};
        default:
            return state;
    }
};

export default reduce
