import {injectIntl} from "react-intl";
import {Dropdown} from "react-bootstrap";
import BalanceItem from "./BalanceItem";
import {Fragment} from "react";
import {connect} from "react-redux";

const HeaderBalanceSubmenu = ({intl, setShowSysWalletToAbey, itwIsAccese}) => {
    const {formatMessage} = intl;
    const onTopUpButtonClick = () => {
        setShowSysWalletToAbey(true);
    };
    return (
        <Fragment>
            <Dropdown.Toggle
                variant={"header"}
                className={'d-flex justify-content-between align-items-center'}>
                <BalanceItem type="balance" icon="faw-abey " />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item className={'balance'}>
                    <Dropdown.ItemText>{formatMessage({id:'COMMON.AW_BALANCE'})}:</Dropdown.ItemText>
                    <BalanceItem type="balanceOrdinary" icon="faw-abey " />
                </Dropdown.Item>
                <Dropdown.Item className={'commissions'}>
                    <Dropdown.ItemText>{formatMessage({id:'COMMON.AW_BONUS_BALANCE'})}:</Dropdown.ItemText>
                    <BalanceItem type="balanceBonus" icon="faw-abey " />
                </Dropdown.Item>
                {itwIsAccese && <Dropdown.Item className={'itw'}>
                    <Dropdown.ItemText>{formatMessage({id:'COMMON.ITW_BALANCE'})}:</Dropdown.ItemText>
                    <BalanceItem type="balanceItw" icon="faw-abey " />
                </Dropdown.Item>}
                <button onClick={onTopUpButtonClick}  className="w-100 redesign-btn btn-square filled mt-1">{formatMessage({id:'AW458.TOP_UP_ABEY_BALANCE'})}</button>
            </Dropdown.Menu>
        </Fragment>

    );
};

const HeaderBalanceSubmenuComponent = connect(
    state => ({
        itwIsAccese: state.member.accessItw,
    })
)(injectIntl(HeaderBalanceSubmenu));

export default injectIntl(HeaderBalanceSubmenuComponent);
